import React from "react"

import { Text, IconButton, Flex, useToast } from "@chakra-ui/react"
import { Menu, MenuButton, MenuList, MenuItem } from "@chakra-ui/react"
import { HiOutlineDocumentAdd, HiOutlineDocumentText } from "react-icons/hi"
import { DeleteIcon, EditIcon } from "@chakra-ui/icons"

const FileUpload = (props) => {
    const inputRef = React.useRef(null)
    const toast = useToast()


    const onClick = () => {
        console.log('clicked')
        inputRef.current.click()
    }
    
    const onFileChange = (e) => {
        if(e.target.files.length > 1) {
            toast({
                title: 'Too many files',
                description: "Please upload one file at a time.",
                status: 'warning',
                duration: 4000,
                isClosable: true,
            })
            return;
        }
        if(e.target.files.length < 1) {
            return;
        }
        
        props.onFileChange(e)
    }
    const onFileReset = () => {
        inputRef.current.value = null
        props.onFileReset()
    }

    return(
        <Flex alignItems='center' marginTop='10px'>
            <input ref={inputRef} type='file' accept={props.accept} style={{display:'none'}} onChange={onFileChange} />


            {props.file ? 
                <Menu>
                    <MenuButton
                        marginRight='10px'
                        as={IconButton}
                        aria-label='Options'
                        icon={<HiOutlineDocumentText size='28px' />}
                        variant='outline'
                    />
                    <MenuList>
                        <MenuItem onClick={() => onClick()} icon={<EditIcon />}>
                            Change file
                        </MenuItem>
                        <MenuItem onClick={() => onFileReset()} color='red.400' icon={<DeleteIcon />} >
                            Delete file
                        </MenuItem>
                    </MenuList>
                </Menu>
                :
                <IconButton onClick={() => onClick()} marginRight='10px' variant='outline' icon={<HiOutlineDocumentAdd size='28px' />} />
            }



            {props.file ? 
                <Text fontSize='xs'>{props.file.name}</Text> :
                <Text fontSize='xs'>{props.defaultText}</Text>
            }

        </Flex>
    )
}

export default FileUpload;