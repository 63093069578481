import React, { useState } from 'react'



import { Box, Flex, Spinner, Text, IconButton, Center, Select } from '@chakra-ui/react'
import { Input, InputGroup, InputLeftElement } from '@chakra-ui/react'
import { Alert, AlertIcon } from '@chakra-ui/react'

import { IoSearchSharp } from 'react-icons/io5'
import { ChevronRightIcon, ChevronLeftIcon } from '@chakra-ui/icons'
import { TableContainer, Table, Thead, Tbody, Th } from '@chakra-ui/react'
import { RadioGroup,Stack, Radio } from '@chakra-ui/react'

import useWindowSize from '../../tools/useWindow'

import './datagrid.css'






const Datagrid = (props) => {
    const [windowWidth,windowHeight] = useWindowSize()

    const [filter,setFilter] = useState('0')


    const [searchTerm,setSearchTerm] = useState('')
    const handleSearchChange = (e) => setSearchTerm(e.target.value);
    const handleSearchConfirm = (e) => {if(e.key === "Enter") props.confirmSearch(e.target.value)}

    const handleNextPage = () => {if(props.page < props.maxPage && !props.loading) props.nextPage()};
    const handlePrevPage = () => {if(props.page > 1 && !props.loading) props.prevPage()};

    const handleFilterChange = (e) => {
        console.log(e)
        setFilter(e);
        props.setFilter(e);
    }

    const handleDropdownFilter = (e) => {
        setFilter(e.target.value);
        props.setFilter(e.target.value);
    }

    const getBottomMargin = () => {
        let tableHeight=windowHeight-276;

        let margin = tableHeight - (props.results.length * 41)

        if(margin < 10) return 100
        else return margin
    }


    const TableFilters = () => {
        if(props.tableFilter) {
            if(props.tableFilter.length > 3) {
                return(
                    <Select 
                        variant='filled' 
                        width='200px' 
                        size='sm' 
                        borderRadius='8px'
                        focusBorderColor="blue.500"
                        value={filter}
                        onChange={handleDropdownFilter}
                    >
                        {props.tableFilter.map((filterItem => {
                            return(
                                <option value={filterItem.value}>{filterItem.label}</option>
                            )
                        }))}
                    </Select>
                )
            }
            else {
                return(
                    <RadioGroup onChange={handleFilterChange} value={filter} >
                        <Stack direction='row' spacing={4}>
                            {props.tableFilter.map((filterItem) => {
                                return(
                                    <Radio size='sm' fontSize='sm' colorScheme='blue' value={filterItem.value}>{filterItem.label}</Radio>
                                )
                            })}
                        </Stack>
                    </RadioGroup>
                )
            }

        }
        else {
            return(
                <Box width='20px' height='20px'>

                </Box>
            )

        }
    }

    const TableFooter = () => {

        if(windowWidth < 800) {
            return(
                <Flex className="datagrid-pagination_wrapper">
                    <Box />
                    <Flex alignItems='center'>
                        <Text fontSize='sm' marginRight='2px' color="textSecondary.500">Page</Text>
                        <Text fontSize='sm' marginRight='2px'>{props.page}</Text>
                        <Text fontSize='sm' marginRight='2px' color="textSecondary.500">of</Text>
                        <Text fontSize='sm' marginRight='35px'>{props.maxPage}</Text>
                        <IconButton icon={<ChevronLeftIcon />}  marginRight='5px' onClick={() => handlePrevPage()} />
                        <IconButton icon={<ChevronRightIcon />} onClick={() => handleNextPage()} />
                    </Flex>
                </Flex>
            )
        }
        else {
            return(
                <Flex className="datagrid-pagination_wrapper">
                    <Flex alignItems='center'>
                        <Text fontSize='sm' color="textSecondary.500">Showing&nbsp;</Text>
                        <Text fontSize='sm' marginRight='2px'>{props.results.length}&nbsp;</Text>
                        <Text fontSize='sm' marginRight='2px' color="textSecondary.500">results</Text>
                    </Flex>
                    <Flex alignItems='center'>
                        <Text fontSize='sm' marginRight='2px' color="textSecondary.500">Page</Text>
                        <Text fontSize='sm' marginRight='2px'>{props.page}</Text>
                        <Text fontSize='sm' marginRight='2px' color="textSecondary.500">of</Text>
                        <Text fontSize='sm' marginRight='35px'>{props.maxPage}</Text>
                        <IconButton icon={<ChevronLeftIcon />}  marginRight='5px' onClick={() => handlePrevPage()} />
                        <IconButton icon={<ChevronRightIcon />} onClick={() => handleNextPage()} />
                    </Flex>
                </Flex>
            )
        }
        
    }


    if(props.loading) {
        return(
            <Box className="datagrid-container">
                <Flex className="datagrid-container_wrapper" alignItems='center' justifyContent='space-between' marginBottom='20px'>
                    <TableFilters />
                    <InputGroup size='sm' style={{width:'30%'}}>
                        <Input 
                            borderRadius='8px'
                            placeholder='Search...' 
                            value={searchTerm}
                            variant='filled'
                            focusBorderColor="blue.500"
                            onChange={handleSearchChange}
                        />
                       
                        <InputLeftElement
                            pointerEvents='none'
                            children={<IoSearchSharp size="18px" color="black"/>}
                        />
                    </InputGroup>  
                </Flex>
                <Flex w='100%' height={window.innerHeight - 272} alignItems='center' justifyContent='center'>
                    <Spinner color='blue.500' size='xl' />
                </Flex>


                <TableFooter />
            </Box>
        )
        
    }
    if(props.error) {
        return(
            <Center className="datagrid-container">
                <Alert status='error' borderRadius='6px' marginBottom='20px' width='260px'>
                    <AlertIcon />
                        Something went wrong...
                </Alert>
            </Center>
        )
        
    }

    return(
        <Box className="datagrid-container" marginBottom={windowWidth < 800 ? "50px" : "0px"}>
            <Flex className="datagrid-container_wrapper" alignItems='center' justifyContent='space-between' marginBottom='20px'>
                <TableFilters />
                <InputGroup size='sm' style={{width:'30%'}}>
                    <Input 
                        borderRadius='8px'
                        placeholder='Search...' 
                        value={searchTerm}
                        variant='filled'
                        focusBorderColor="blue.500"
                        onChange={handleSearchChange}
                        onKeyPress={e => handleSearchConfirm(e)}
                    />
                    <InputLeftElement
                        pointerEvents='none'
                        children={<IoSearchSharp size="18px" color="black"/>}
                    />
                </InputGroup>  
            </Flex>
            <Box className="datagrid-tbody_wrapper">
                <TableContainer>
                        <Table variant='simple' size='sm' marginBottom={`${getBottomMargin()}px`}>
                            <Thead>
                                {props.tableHeader.map((headerItem) => {
                                    return(
                                        headerItem.width ? 
                                            <Th width={headerItem.width}>{headerItem.label}</Th> :
                                            <Th>{headerItem.label}</Th>
                                    )
                                })}
                            </Thead>
                            <Tbody id='tableBody'>
                                {props.tableContent}
                            </Tbody>

                        </Table>
                </TableContainer>
            </Box>
            
            <TableFooter />
        </Box>
    )
}

export default Datagrid;