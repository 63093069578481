import { createSlice } from "@reduxjs/toolkit";
import { api } from "./api";

export const initialState = {
    loading: false,
    error:false,

    

    files:[],
    fileAmount:0,

    page: 1,
    maxPage:1,
    search:"",
    filter:'0',

    toastType:null,
    toastValue:"Success!",
}

const staffKpnSlice = createSlice({
    name:'staffKpn',
    initialState,
    reducers: {

        setLoading: (state, action) => {state.loading = action.payload},
        setError: (state, action) => {state.error = action.payload},



        setFileAmount: (state, action) => {state.fileAmount = action.payload},
        setFiles: (state, action) => {state.files = action.payload},

        nextPage: (state) => {state.page++},
        previousPage: (state) => {state.page--;},
    
        setMaxPage: (state,action) => {state.maxPage = action.payload},
        setPageSize: (state,action) => {state.pageSize = action.payload},
    
        setSearch: (state, action) => {
            state.search = action.payload;
            state.page = 1;
        },
        setFilter: (state, action) => {state.filter = action.payload},

        setToastValue: (state, action) => {state.toastValue = action.payload},
        setToastType: (state, action) => {state.toastType = action.payload}
    }
})

export const {

    setLoading, setError, setFileUploadState, nextPage, previousPage, setMaxPage, setPageSize,
    setToastType, setToastValue, setSearch, setFilter, setFileAmount, setFiles, setFileDeleteState

} = staffKpnSlice.actions

export default staffKpnSlice.reducer;





export function getFiles(page,pageSize,search,filter) {
    return async (dispatch) => {
        dispatch(setLoading(true))
        api
            .get(`kpn/file/kpn?page=${page}&search=${search}&filter=${filter}&page_size=${pageSize}`)
            .then((res) => {
                console.log(res.data)

                dispatch(setFileAmount(res.data.total_amount))
                dispatch(setFiles(res.data.users))
                dispatch(setMaxPage(res.data.max_page))
                
                dispatch(setLoading(false))
                dispatch(setError(false))
            })
            .catch((err) => {
                dispatch(setLoading(false))
                dispatch(setError(true))
            })
    }
}




