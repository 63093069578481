import { createSlice } from "@reduxjs/toolkit";
import { api } from "./api";

export const initialState = {
    loading: false,
    error:false,

    

    tasks:[],
    taskAmount:0,

    page: 1,
    maxPage:1,
    search:"",

    toastType:null,
    toastValue:"Success!",
}

const staffTask = createSlice({
    name:'staffTask',
    initialState,
    reducers: {

        setLoading: (state, action) => {state.loading = action.payload},
        setError: (state, action) => {state.error = action.payload},



        setTaskAmount: (state, action) => {state.taskAmount = action.payload},
        setTasks: (state, action) => {state.tasks = action.payload},

        nextPage: (state) => {state.page++},
        previousPage: (state) => {state.page--;},
    
        setMaxPage: (state,action) => {state.maxPage = action.payload},
        setPageSize: (state,action) => {state.pageSize = action.payload},
    
        setSearch: (state, action) => {
            state.search = action.payload;
            state.page = 1;
        },
        setFilter: (state, action) => {state.filter = action.payload},

        setToastValue: (state, action) => {state.toastValue = action.payload},
        setToastType: (state, action) => {state.toastType = action.payload}
    }
})

export const {

    setLoading, setError, setFileUploadState, nextPage, previousPage, setMaxPage, setPageSize,
    setToastType, setToastValue, setSearch, setTasks, setTaskAmount

} = staffTask.actions

export default staffTask.reducer;

export function getTasks(page,pageSize,search,tasks) {
    return async (dispatch) => {
        if(tasks.length === 0) dispatch(setLoading(true))
        dispatch(setError(false))

        api
            .get(`kpn/customer/list/task?page=${page}&search=${search}&page_size=${pageSize}`)
            .then((res) => {
                if(tasks.length === 0) dispatch(setLoading(false))
                dispatch(setTasks(res.data.tasks))    
                dispatch(setMaxPage(res.data.max_page))
                dispatch(setTaskAmount(res.data.total_amount))
            })
            .catch((err) => {
                if(tasks.length === 0) dispatch(setError(true))
                dispatch(setToastType('info'))
                dispatch(setToastValue('Could not update task states'))
            })

    }
}