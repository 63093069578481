
import React, { useState } from "react";
import StaffPageWrapper from "./PageWrapper";
import FadeIn from 'react-fade-in'
import Header from '../../components/header/Header'
import Paginator from '../../components/paginator/Paginator'


import { useDispatch } from "react-redux";
import { setPageSize, setPage, matchContacts, setSearch, setFilter, unmatchContacts } from "../../slices/staff/customer";
import { Button, Divider, Tab, TabList, Tabs } from "@chakra-ui/react";
import StaffCustomersKpn from "./CustomersKpn";
import StaffCustomersAdHoc from "./CustomersAdHoc";


const PAGE_SIZE = 24;





const StaffCustomers = () => {
    const dispatch = useDispatch()

    const [tab,setTab] = useState(0)
    const handleTabChange = (i) => {
        dispatch(setSearch(''))
        dispatch(setFilter('0'))
        dispatch(setPage(1))
        dispatch(setPageSize(1))
        setTab(i)
    }


    const getPageTitle = () => {
        switch(tab) {
            case 0: return 'KPN / D2D Customers';
            case 1: return 'AdHoc Customers';

            default: return 'Teleknowledge Customers';
        }
    }
    const PageContent = () => {
        switch(tab) {
            case 0: return <StaffCustomersKpn />
            case 1: return <StaffCustomersAdHoc />

            default: return <StaffCustomersKpn />
        }
    }


    return(
        <StaffPageWrapper page={
            <div style={{overflow:'hidden', paddingBottom:'20px'}}>
                <FadeIn>
                    <Header
                        title={getPageTitle()}
                        date={false}
                        stat={false}
                        statLabel="customers in database"
                        rightElements={<>
                            <Button variant='solid' bg='white' size='sm' marginRight='5px'>Match</Button>
                            <Button variant='solid' bg='white' size='sm'>Unmatch</Button>
                            <Divider orientation='vertical' borderColor='gray.400' marginLeft='10px' marginRight='10px' />
                            <Tabs index={tab} onChange={handleTabChange} variant='solid-rounded' colorScheme='cyan'>
                                <TabList>
                                    <Tab fontSize='xs' value='0' borderRadius='6px'>KPN / D2D</Tab>
                                    <Tab fontSize='xs' value='1' borderRadius='6px'>AdHoc</Tab>
                                </TabList>
                            </Tabs>
                        </>}
                    />
                   <PageContent />
                    
                </FadeIn>
            </div>
        }
        />
    )
}


export default StaffCustomers;