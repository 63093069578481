
import { Button, Text, IconButton } from "@chakra-ui/react";
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter } from "@chakra-ui/react";
import { useDisclosure } from "@chakra-ui/react";



import { DeleteIcon } from "@chakra-ui/icons";







const KpnDeleteModal = (props) => {

    const { isOpen, onOpen, onClose } = useDisclosure()


    const handleDelete = async () => {
        

    }
    const handleClose = () => onClose();







    return(
        <>
            <IconButton size={props.size} variant='outline' icon={<DeleteIcon color='red.500' />} onClick={() => onOpen()} />
            <Modal isOpen={isOpen} onClose={handleClose} isCentered scrollBehavior="inside">
                <ModalOverlay />
                <ModalContent  bg='primary.500' width='400px'>
                    <ModalHeader>Delete {props.type === 'K' ? 'KPN' : 'D2D'} File</ModalHeader>
                    <ModalCloseButton />

                    <ModalBody>

                        <Text fontSize='sm' color='textSecondary.500'>Deleting this file will result in all associated customers to be wiped from the database. Please proceed with caution!</Text>

                    </ModalBody>

                    <ModalFooter>
                        <Button leftIcon={<DeleteIcon />} variant='solid' marginLeft='10px' onClick={() => handleDelete()}>
                            Delete
                        </Button>
                    </ModalFooter>

                </ModalContent>
            </Modal>
            
        </>
        
    )
}



export default KpnDeleteModal;