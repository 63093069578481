import React, { useEffect } from "react";
import { useColorMode, useToast } from "@chakra-ui/react";
import { HashRouter, Route, Routes } from "react-router-dom";



import StaffMaster from "./pages/staff/Master";
import StaffCustomers from "./pages/staff/Customers";

import StaffCustomerFiles from "./pages/staff/CustomerFiles";
import StaffApi from "./pages/staff/Api";
import StaffSalesFiles from './pages/staff/SalesFiles'

import StaffSettings from './pages/staff/Settings'
import StaffUsers from './pages/staff/Users'
import StaffSales from "./pages/staff/Sales";

import StaffTasks from "./pages/staff/Tasks";



const Page = () => {

    return(
        <HashRouter>

            <Routes>
                <Route path='/staff' element={<StaffMaster />} />
                <Route path='/staff/master' element={<StaffMaster />} />
                <Route path='/staff/customers' element={<StaffCustomers />} />
                <Route path='/staff/sales' element={<StaffSales />} />

                <Route path='/staff/customers/files' element={<StaffCustomerFiles />} />
                <Route path='/staff/sales/files' element={<StaffSalesFiles />} />


                <Route path='/staff/tasks' element={<StaffTasks />} />
                <Route path='/staff/api' element={<StaffApi />} />
                <Route path='/staff/users' element={<StaffUsers />} />
                <Route path='/staff/settings' element={<StaffSettings />} />


                
            </Routes>
        </HashRouter>
    )
}

const Main = () => {
    const toast = useToast()
    const { colorMode, toggleColorMode } = useColorMode;
    if(colorMode === 'dark') toggleColorMode()

    useEffect(() => {
        toast({
            title:'Demo version',
            description:'This page is the demo version and is not linked to any backend. All data are placeholder values and all actions are disabled.',
            isClosable: true,
        })
    },[])

    return(<Page />)
}


export default Main;

