
import React from "react";
import StaffPageWrapper from "./PageWrapper";
import FadeIn from 'react-fade-in'
import Header from '../../components/header/Header'
import Datagrid from "../../components/table/Datagrid";

import { Tag, Td, Tr, Text, IconButton } from "@chakra-ui/react";

import { Link } from "react-router-dom";


import { HiOutlineDocumentText } from "react-icons/hi";
import { DownloadIcon } from "@chakra-ui/icons";

const TABLE_HEAD = [
    {
        label:'',
        width:'20px'
    },
    {
        label:'ID'
    },
    {
        label:'Name'
    },
    {
        label:'Phone'
    },
    {
        label:'Address'
    },
    {
        label:'',
    }
]



const saleAmount = 4
const data = [
    {
      id: 1,
      company_name: 'TechNL',
      phone: '123-456-7890',
      address: 'Amsterdam, Netherlands',
    },
    {
      id: 2,
      company_name: 'DataBV',
      phone: '987-654-3210',
      address: 'Utrecht, Netherlands',
    },
    {
      id: 3,
      company_name: 'DesignFirm',
      phone: '555-555-5555',
      address: 'Rotterdam, Netherlands',
    },
    {
      id: 4,
      company_name: 'InnovateBouw',
      phone: '444-444-4444',
      address: 'Den Haag, Netherlands',
    },
    {
      id: 5,
      company_name: 'GroenTech',
      phone: '777-777-7777',
      address: 'Eindhoven, Netherlands',
    },
];




const StaffSales = () => {




    const TableContent = () => {
        return(
            <>
                {data.map((data) => {
                    return(
                        <Tr>
                            <Td><HiOutlineDocumentText color='black' size='20px' /></Td> 
                            <Td><Text fontSize='xs' noOfLines={1}>{data.id}</Text></Td>
                            <Td>
                                <Link to='/staff/master/1'>
                                    <Tag cursor='pointer'>
                                        <Text fontSize='xs' noOfLines={1}>{data.company_name}</Text>
                                    </Tag>
                                </Link>
                            </Td>
                            <Td>
                                <Tag >
                                    <Text fontSize='xs' noOfLines={1}>{data.phone}</Text>
                                </Tag>
                            </Td>
                            <Td><Text fontSize='xs' noOfLines={1}>{data.address}</Text></Td>
                            <Td>
                                <IconButton size='xs' variant='outline' icon={<DownloadIcon color='green.500' />} />
                            </Td>
                        </Tr>
                    )
                })}
            </>
        )
    }


    return(
        <StaffPageWrapper page={
            <div style={{overflow:'hidden', paddingBottom:'20px'}}>
                <FadeIn>
                    <Header
                        title="Sales"
                        date={false}
                        stat={true}
                        statValue={saleAmount}
                        statLabel="customers in database"
                    />
                    <Datagrid 
                        tableHeader={TABLE_HEAD}
                        tableContent={<TableContent />}

                        loading={false}
                        error={false}

                        results={data}
                        page={1}
                        maxPage={1}

                    />
                </FadeIn>
            </div>
        }
        />
    )
}


export default StaffSales;