import { Box, Flex, Text } from "@chakra-ui/react";

import './navbar.css'



const NavbarButton = (props) => {
    return(
        <Flex className='navbar_button-container'>
            <Box width='12px'/>
            {props.icon}

            <Text userSelect='none' marginLeft='15px'>{props.label}</Text>
        </Flex>
    )
}

export default NavbarButton;