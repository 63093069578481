
import React, { useState } from "react";


import StaffPageWrapper from "./PageWrapper";
import FadeIn from 'react-fade-in'
import Header from '../../components/header/Header'
import Timeline from '../../components/timeline/index'

import {  Box, useToast, Text, Flex, VStack, Button, Divider, Tag, Tooltip, Alert, AlertIcon, Center, Skeleton, SimpleGrid } from "@chakra-ui/react";
import { HiOutlineReply, HiOutlineUserGroup, HiOutlineCash, HiOutlineFolderOpen, HiOutlineDatabase, HiOutlineInbox, HiOutlineReceiptTax, HiOutlinePhone, HiOutlineLogout } from "react-icons/hi";

import MasterImportPanel from "../../components/panels/MasterImportPanel";

import AdHocImportModal from "../../components/modals/AdHocImportModal";



import { getMasterbestand, getAllLogs, setToastType } from "../../slices/staff/master";
import { logoutUser } from "../../slices/staff/auth";

import { useDispatch, useSelector } from "react-redux";


import './page.css'
import DetailsPanel from "../../components/details/DetailsPanel";
import useWindowSize from "../../tools/useWindow";
import NettoImportModal from "../../components/modals/NettoImportModal";
import KpnImportModal from "../../components/modals/KpnImportModal";
import { Link } from "react-router-dom";


const TeleknowledgeIcon = (props) => {
    return <Tooltip hasArrow label={props.label} fontSize='sm' bg='secondary.500' color='textMain.500'>
        <Tag marginRight='12px' size='xl' backgroundColor='transparent'>
            <HiOutlinePhone size='20px' color={props.color}/>
        </Tag>
    </Tooltip>
} 

const LeadDeskIcon = (props) => {
    return <Tooltip hasArrow label={props.label} fontSize='sm' bg='secondary.500' color='textMain.500'>
        <Tag marginRight='12px' size='xl' backgroundColor='transparent'>
            <HiOutlineInbox size='20px' color={props.color}/>
        </Tag>
    </Tooltip>
} 

const SalesdockIcon = (props) => {
    return <Tooltip hasArrow label={props.label} fontSize='sm' bg='secondary.500' color='textMain.500'>
        <Tag marginRight='12px' size='xl' backgroundColor='transparent'>
            <HiOutlineReceiptTax size='20px' color={props.color}/>
        </Tag>
    </Tooltip>
} 


const nettoFilesAmount = 3
const kpnFilesAmount = 6
const customers = 2512
const sales = 540
const kpnFiles = [
    {
      id: 1,
      name: 'File 1',
      user: 'User 1',
      file: 'file_contents_1',
      file_variant: 1,
      file_variant_label: 'Type 1',
      timestamp: '2023-08-16T12:00:00Z',
      customer_amount: 10,
    },
    {
      id: 2,
      name: 'File 2',
      user: 'User 2',
      file: 'file_contents_2',
      file_variant: 2,
      file_variant_label: 'Type 2',
      timestamp: '2023-08-16T13:00:00Z',
      customer_amount: 15,
    },
    {
      id: 3,
      name: 'File 3',
      user: 'User 3',
      file: 'file_contents_3',
      file_variant: 3,
      file_variant_label: 'Type 3',
      timestamp: '2023-08-16T14:00:00Z',
      customer_amount: 20,
    },
    {
      id: 4,
      name: 'File 4',
      user: 'User 4',
      file: 'file_contents_4',
      file_variant: 1,
      file_variant_label: 'Type 1',
      timestamp: '2023-08-16T15:00:00Z',
      customer_amount: 12,
    },
    {
      id: 5,
      name: 'File 5',
      user: 'User 5',
      file: 'file_contents_5',
      file_variant: 2,
      file_variant_label: 'Type 2',
      timestamp: '2023-08-16T16:00:00Z',
      customer_amount: 18,
    },
    {
      id: 6,
      name: 'File 6',
      user: 'User 6',
      file: 'file_contents_6',
      file_variant: 3,
      file_variant_label: 'Type 3',
      timestamp: '2023-08-16T17:00:00Z',
      customer_amount: 22,
    },
];
const nettoFiles = [
    {
      id: 1,
      name: 'Sales 1',
      user: 'User A',
      file: 'sales_file_contents_1',
      file_variant: 1,
      file_variant_label: 'Type X',
      timestamp: '2023-08-16T12:00:00Z',
      customer_amount: 10,
    },
    {
      id: 2,
      name: 'Sales 2',
      user: 'User B',
      file: 'sales_file_contents_2',
      file_variant: 2,
      file_variant_label: 'Type Y',
      timestamp: '2023-08-16T13:00:00Z',
      customer_amount: 15,
    },
    {
      id: 3,
      name: 'Sales 3',
      user: 'User C',
      file: 'sales_file_contents_3',
      file_variant: 3,
      file_variant_label: 'Type Z',
      timestamp: '2023-08-16T14:00:00Z',
      customer_amount: 20,
    },
];

const log = [
    {
      text: 'Felix imported an additional KPN file with 10 new customers',
      key: '1',
    },
    {
      text: 'Alice imported a sales file with 15 new customers',
      key: '2',
    },
    {
      text: 'John exported a report to PDF',
      key: '3',
    },
    {
      text: 'Sarah updated customer information for ID 123',
      key: '4',
    },
    {
      text: 'David analyzed sales data for Q2',
      key: '5',
    },
    {
      text: 'Emma created a new account for a customer',
      key: '6',
    },
    {
      text: 'Michael reviewed financial projections',
      key: '7',
    },
    {
      text: 'Sophia resolved a technical issue with the app',
      key: '8',
    },
    {
      text: 'Liam conducted a training session for new employees',
      key: '9',
    },
    {
      text: 'Olivia collaborated with the marketing team on a campaign',
      key: '10',
    },
    {
      text: 'Daniel presented a project proposal to the management',
      key: '11',
    },
    {
      text: 'Ava organized a team-building event',
      key: '12',
    },
  ];
  

  
  



const StaffMaster = (props) => {
    const toast = useToast()
    const dispatch = useDispatch()
    const [width,height] = useWindowSize()

   //const { loading, error } = useSelector(state => state.staffMaster)
   //const { log, kpnFiles, kpnFilesAmount, nettoFiles, nettoFilesAmount, customers, sales } = useSelector(state => state.staffMaster)
    const { toastType, toastValue } = useSelector(state => state.staffMaster)


    const handleLogout = () => dispatch(logoutUser())
    const handleGetAllLogs = () => dispatch(getAllLogs())

    React.useEffect(() => {
        if(toastType) toast({
            title:toastValue,
            status: toastType,
            duration: 4000,
            isClosable: true,
        })
        dispatch(setToastType(null))
    })

    React.useEffect(() => {
        dispatch(getMasterbestand())
    }, [])


    const getDetailsWidth = () => {
        if(width > 1268) return '70%'
        else if(width > 950) return '50%'
        else return '100%'
    }
    const getLogWidth = () => {
        if(width > 1268) return '28%'
        else if(width > 950) return '48%'
        else return '100%'
    }

    const PageContent = () => {
        return(
            <>
                <Box w={getDetailsWidth()}>
                    <SimpleGrid marginBottom='10px' minChildWidth='350px' spacing='10px'>
                        <DetailsPanel
                            height={280}
                            title='Details'
                            content={<>
                                <Flex alignItems='center' marginBottom='10px'>
                                    <HiOutlineDatabase size='22px' color='#7f7f7f' />
                                    <Text fontSize='sm' marginLeft='5px' color='textSecondary.500'>{kpnFilesAmount} KPN files imported</Text>
                                </Flex>
                                <Flex alignItems='center' marginBottom='10px'>
                                    <HiOutlineFolderOpen size='22px' color='#7f7f7f' />
                                    <Text fontSize='sm' marginLeft='5px' color='textSecondary.500'>{nettoFilesAmount} Netto files imported</Text>
                                </Flex>
                                <Flex alignItems='center' marginBottom='10px'>
                                    <HiOutlineUserGroup size='22px' color='#7f7f7f' />
                                    <Text fontSize='sm' marginLeft='5px' color='textSecondary.500'>{customers} customers detected</Text>
                                </Flex>
                                <Flex alignItems='center' marginBottom='10px'>
                                    <HiOutlineCash size='22px' color='#7f7f7f' />
                                    <Text fontSize='sm' marginLeft='5px' color='textSecondary.500'>{sales} sales made</Text>
                                </Flex>
                                <Divider marginBottom='14px' />

                                <Flex alignItems='center' justifyContent='right'>
                                    
                                    {true ?
                                        <TeleknowledgeIcon label='Linked to Teleknowledge!' color='#009900' /> :
                                        <TeleknowledgeIcon label='Not linked to Teleknowledge!' color='#F6AD55' />    
                                    }

                                    {true ? 
                                        <LeadDeskIcon label='Linked to LeadDesk!' color='#009900' /> : 
                                        <LeadDeskIcon label='Not linked to LeadDesk!' color='#F6AD55' />
                                    }

                                    {props.salesdock ? 
                                        <SalesdockIcon label='Linked to SalesDock!' color='#009900' /> :
                                        <SalesdockIcon label='Not linked to SalesDock!' color='#F6AD55' />                
                                    }


                                    

                                </Flex>
                            </>}
                        />
                        <DetailsPanel
                            height={280}
                            title='Actions'
                            content={<>
                                <KpnImportModal />
                                <Box h='10px' />
                                <AdHocImportModal />
                                <Box h='10px' />
                                <NettoImportModal />
                            </>}
                        />
                    </SimpleGrid>
                    <SimpleGrid marginBottom='10px' minChildWidth='350px' spacing='10px'>
                        <Box>
                            <Text fontSize='md' color='textPrimary.500' marginBottom='10px'>Customer Imports</Text>
                            <VStack className='stack-container' height='500px' padding='0px 4px 4px 4px'>
                            {kpnFiles.map((file) => <MasterImportPanel 
                                id={file.id}
                                name={file.name}
                                user={file.user}
                                file={file.file}
                                fileTypeId={file.file_variant}
                                fileType={file.file_variant_label}
                                timestamp={file.timestamp}
                                count={file.customer_amount}

                            />)}
                
                                
                            </VStack>
                            {kpnFiles.length > 5 ?
                                <Link to='/staff/customers/files'>
                                    <Button bg='white' size='sm' marginTop='10px' marginLeft='5px'>View All</Button>
                                </Link> : <></>
                            }
                        </Box>

                        <Box>
                            <Text fontSize='md' color='textPrimary.500' marginBottom='10px'>Sales Imports</Text>
                            <VStack className='stack-container' height='500px' padding='0px 4px 4px 4px'>
                            {nettoFiles.map((file) => <MasterImportPanel 
                                id={file.id}
                                name={file.name}
                                user={file.user}
                                file={file.file}
                                fileTypeId={file.file_variant}
                                fileType={file.file_variant_label}
                                timestamp={file.timestamp}
                                count={file.customer_amount}

                            />)}
                
                                
                            </VStack>
                            {nettoFiles.length > 5 ? 
                                <Link to='/staff/sales/files'>
                                    <Button bg='white' size='sm' marginTop='10px' marginLeft='5px'>View All</Button>
                                </Link> : <></>
                            }
                        </Box>
                        
                    
                    </SimpleGrid>
                </Box>

                <Box w={getLogWidth()} className='stack-container' h='870px'>
                    <Timeline items={log} format="hh:mm a" />
                    <Button bg='white' size='sm' marginTop='10px' marginLeft='5px' onClick={() => handleGetAllLogs()}>View All</Button>

                </Box>


            </>
            
        )
    }



    return(
        <StaffPageWrapper page={
            <div style={{overflow:'hidden', paddingBottom:'20px'}}>
                <FadeIn>
                    <Header
                        title="Master"
                        date={true}
                        stat={false}
                        rightElements={<>
                            <Button size='sm' marginLeft='10px' bg='white' leftIcon={<HiOutlineReply />}>Export</Button>
                            <Button size='sm' marginLeft='10px' bg='white' leftIcon={<HiOutlineLogout />} onClick={() => handleLogout()}>Log out</Button>
                        </>}
                    />
                    <Flex className='content-container'>

                    <PageContent />

                    </Flex>

                    
                    
                </FadeIn>
            </div>
        }
        />
    )
}


export default StaffMaster;