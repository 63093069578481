
import Navbar from "./Navbar";
import MobileNavbar from "./NavbarMobile";
import NavbarButton from "./NavbarButton";

import useWindowSize from "../../tools/useWindow";

import { Divider } from "@chakra-ui/react";

import { HiOutlineArchive, HiOutlinePaperAirplane, HiOutlineFolderOpen, HiOutlineDatabase, HiOutlineCog, HiOutlineUser, HiOutlineUserGroup, HiOutlineCash, HiOutlineCode } from 'react-icons/hi'

import { Link } from "react-router-dom";


const ResponsiveNavbar = (props) => {
    const [windowWidth,windowHeight] = useWindowSize();


    const MenuItems = () => {
        return(
            <>
                <Divider />

                <Link to='/staff/master' style={{width:'100%'}}>
                    <NavbarButton label='Master' icon={<HiOutlineArchive size='22px' />} />
                </Link>
                <Link to='/staff/customers' style={{width:'100%'}}>
                    <NavbarButton label='Customers' icon={<HiOutlineUserGroup size='22px' />} />
                </Link>
                <Link to='/staff/sales' style={{width:'100%'}}>
                    <NavbarButton label='Sales' icon={<HiOutlineCash size='22px' />} />
                </Link>

                <Divider />

                <Link to='/staff/customers/files' style={{width:'100%'}}>
                    <NavbarButton label='Customer Files' icon={<HiOutlineDatabase size='22px' />} />
                </Link>
                <Link to='/staff/sales/files' style={{width:'100%'}}>
                    <NavbarButton label='Sales Files' icon={<HiOutlineFolderOpen size='22px' />} />
                </Link>
                <Link to='/staff/api' style={{width:'100%'}}>
                    <NavbarButton label='API Data' icon={<HiOutlinePaperAirplane size='22px' />} />
                </Link>
                
                <Divider />

                <Link to='/staff/tasks' style={{width:'100%'}}>
                    <NavbarButton label='Tasks' icon={<HiOutlineCode size='22px' />} />
                </Link>

                <Link to='/staff/users' style={{width:'100%'}}>
                    <NavbarButton label='Users' icon={<HiOutlineUser size='22px' />} />
                </Link>
                <Link to='/staff/settings' style={{width:'100%'}}>
                    <NavbarButton label='Settings' icon={<HiOutlineCog size='22px' />} />
                </Link>
            </>
        )
    }


    if(windowWidth < 720) {
        return(
            <MobileNavbar 
                menuItems={<MenuItems />}
            />
     
        )
    }
    else {
        return(
            <Navbar
                menuItems={<MenuItems />}
            />
           
        )
    }

}


export default ResponsiveNavbar;