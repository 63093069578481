import { createSlice } from "@reduxjs/toolkit";


export const initialState = {
    loading: false,
    error:false,

    data:[],
    dataAmount:0,

    page: 1,
    maxPage:1,
    search:"",
    filter:'0',

    toastType:null,
    toastValue:"Success!",
}

const staffTeleknowledgeSlice = createSlice({
    name:'staffTeleknowledge',
    initialState,
    reducers: {

        setLoading: (state, action) => {state.loading = action.payload},
        setError: (state, action) => {state.error = action.payload},

        setDataAmount: (state, action) => {state.dataAmount = action.payload},
        setData: (state, action) => {state.data = action.payload},

        nextPage: (state) => {state.page++},
        previousPage: (state) => {state.page--;},
    
        setMaxPage: (state,action) => {state.maxPage = action.payload},
        setPageSize: (state,action) => {state.pageSize = action.payload},
    
        setSearch: (state, action) => {
            state.search = action.payload;
            state.page = 1;
        },
        setFilter: (state, action) => {state.filter = action.payload},

        setToastValue: (state, action) => {state.toastValue = action.payload},
        setToastType: (state, action) => {state.toastType = action.payload}
    }
})

export const {

    setLoading, setError, nextPage, previousPage, setMaxPage, setPageSize,
    setToastType, setToastValue, setSearch, setFilter, setFileAmount, setFiles

} = staffTeleknowledgeSlice.actions

export default staffTeleknowledgeSlice.reducer;




export function getData(page,pageSize,search,filter) {
    return async (dispatch) => {

    }
}
