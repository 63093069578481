
import React from "react";
import FadeIn from 'react-fade-in';
import Datagrid from '../../components/table/Datagrid'

import { IconButton, Tag, Td, Text, Tr } from "@chakra-ui/react";
import { HiOutlineCash } from "react-icons/hi";
import { DownloadIcon } from "@chakra-ui/icons";





const PAGE_SIZE = 25;
const TABLE_HEAD = [
    {
        label:'',
        width:'20px'
    },
    {
        label:'id'
    },
    {
        label:'name'
    },
    {
        label:'email'
    },
    {
        label:'address'
    },
    {
        label:'',
    }
]


const data = [
    {
      id: 1,
      company_name: 'TechNL',
      phone: '123-456-7890',
      address: 'Amsterdam, Netherlands',
    },
    {
      id: 2,
      company_name: 'DataBV',
      phone: '987-654-3210',
      address: 'Utrecht, Netherlands',
    },
    {
      id: 3,
      company_name: 'DesignFirm',
      phone: '555-555-5555',
      address: 'Rotterdam, Netherlands',
    },
    {
      id: 4,
      company_name: 'InnovateBouw',
      phone: '444-444-4444',
      address: 'Den Haag, Netherlands',
    },
    {
      id: 5,
      company_name: 'GroenTech',
      phone: '777-777-7777',
      address: 'Eindhoven, Netherlands',
    },
];

const StaffTeleknowledge = () => {

    const TableContent = () => {
        return(
            <>
            {data.map((data) => {
                return(
                    <Tr>
                        <Td><HiOutlineCash color='black' size='20px' /></Td> 
                        <Td><Text fontSize='xs' noOfLines={1}>{data.id}</Text></Td>
                        <Td>
                            <Tag cursor='pointer'>
                                <Text fontSize='xs' noOfLines={1}>{data.company_name}</Text>
                            </Tag>
                        </Td>
                        <Td>
                            <Tag >
                                <Text fontSize='xs' noOfLines={1}>{data.phone}</Text>
                            </Tag>
                        </Td>
                        <Td><Text fontSize='xs' noOfLines={1}>{data.address}</Text></Td>
                        <Td>
                            <IconButton size='xs' variant='outline' icon={<DownloadIcon color='green.500' />} />
                        </Td>
                    </Tr>
                )})}
            </>
        )
    }


    return(
        <FadeIn>
            <Datagrid 
                tableHeader={TABLE_HEAD}
                tableContent={<TableContent />}

                loading={false}
                error={false}

                results={data}
                page={1}
                maxPage={1}
            />
        </FadeIn>
        
    )

}




export default StaffTeleknowledge;