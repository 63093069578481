import { createSlice } from "@reduxjs/toolkit";
import { api } from "./api";

export const initialState = {
    loading: false,
    error:false,

    customers:[],
    customerAmount:0,

    adhoc:[],
    adhocAmount:0,

    page: 1,
    maxPage:1,
    search:"",
    filter:'0',

    toastType:null,
    toastValue:"Success!",
}

const staffCustomerSlice = createSlice({
    name:'staffCustomer',
    initialState,
    reducers: {

        setLoading: (state, action) => {state.loading = action.payload},
        setError: (state, action) => {state.error = action.payload},

        setCustomerAmount: (state, action) => {state.customerAmount = action.payload},
        setCustomers: (state, action) => {state.customers = action.payload},


        setAdhocAmount: (state, action) => {state.adhocAmount = action.payload},
        setAdhoc: (state, action) => {state.adhoc = action.payload},

        nextPage: (state) => {state.page++},
        previousPage: (state) => {state.page--;},
        
        setPage: (state,action) => {state.page = action.payload},
        setMaxPage: (state,action) => {state.maxPage = action.payload},
        setPageSize: (state,action) => {state.pageSize = action.payload},
    
        setSearch: (state, action) => {
            state.search = action.payload;
            state.page = 1;
        },
        setFilter: (state, action) => {state.filter = action.payload},

        setToastValue: (state, action) => {state.toastValue = action.payload},
        setToastType: (state, action) => {state.toastType = action.payload}
    }
})

export const {

    setLoading, setError, nextPage, previousPage, setMaxPage, setPageSize, setPage,
    setToastType, setToastValue, setSearch, setFilter, setCustomerAmount, setCustomers,
    setAdhoc, setAdhocAmount

} = staffCustomerSlice.actions

export default staffCustomerSlice.reducer;




export function getCustomers(page,pageSize,search,filter) {
    return async (dispatch) => {
        dispatch(setLoading(true))
        api
            .get(`kpn/customer/kpn?page=${page}&search=${search}&filter=${filter}&page_size=${pageSize}`, {timeout:15000})
            .then((res) => {
                dispatch(setCustomerAmount(res.data.total_amount))
                dispatch(setMaxPage(res.data.max_page))
                dispatch(setCustomers(res.data.users))

                dispatch(setLoading(false))
                dispatch(setError(false))
            })
            .catch((err) => {
                dispatch(setLoading(false))
                dispatch(setError(true))
            })
    }
}

export function getAdHoc(page,pageSize,search,filter) {
    return async (dispatch) => {
        dispatch(setLoading(true))

        api
            .get(`kpn/customer/adhoc?page=${page}&search=${search}&filter=${filter}&page_size=${pageSize}`, {timeout:15000})
            .then((res) => {
                console.log(res.data)
                dispatch(setAdhocAmount(res.data.total_amount))
                dispatch(setMaxPage(res.data.max_page))
                dispatch(setAdhoc(res.data.users))

                dispatch(setLoading(false))
                dispatch(setError(false))
            })
            .catch((err) => {
                dispatch(setLoading(false))
                dispatch(setError(true))
            })
    }
}

export function matchContacts() {
    return async (dispatch) => {
        api
            .get('/kpn/match?task=match')
            .then((res) => {
                if(res.data.success) dispatch(setToastType('success'))
                else dispatch(setToastType('info'))

                dispatch(setToastValue(res.data.message))
            })
            .catch((err) => {
                dispatch(setToastType('error'))
                dispatch(setToastValue('Something went wrong...'))
            })
    }
}
export function unmatchContacts() {
    return async (dispatch) => {
        api
            .get('/kpn/match?task=unmatch')
            .then((res) => {
                if(res.data.success) dispatch(setToastType('success'))
                else dispatch(setToastType('info'))

                dispatch(setToastValue(res.data.message))
            })
            .catch((err) => {
                dispatch(setToastType('error'))
                dispatch(setToastValue('Something went wrong...'))
            })
    }
}


