
import React, { useState, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";

import FadeIn from 'react-fade-in'

import { api } from "../../slices/staff/api";

import { Button, useToast, Alert, AlertIcon, Progress, Text, Center, Box, IconButton } from "@chakra-ui/react";
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter } from "@chakra-ui/react";
import { useDisclosure } from "@chakra-ui/react";


import { setFileDeleteState, setToastType, setToastValue } from "../../slices/staff/files";
import { getMasterbestand } from "../../slices/staff/master";
import { getFiles, getKpnFiles } from "../../slices/staff/kpn";

import { useDispatch, useSelector } from "react-redux";
import { DeleteIcon, DownloadIcon } from "@chakra-ui/icons";





const CHUNK_SIZE = 1048576 * 3;      //its 3MB, increase the number measure in mb


const sleep = (milliseconds) => {
    return new Promise(resolve => setTimeout(resolve, milliseconds))
}

const AdHocDeleteModal = (props) => {
    const toast = useToast()
    const dispatch = useDispatch()

    const { isOpen, onOpen, onClose } = useDisclosure()
    const { fileDeleteState, toastType, toastValue } = useSelector(state => state.staffFile)


    const [showProgress, setShowProgress] = useState(false)
    const [progress, setProgress] = useState(0);
    const [taskId,setTaskId] = useState('');

    const [deletingCustomers,setDeletingCustomers] = useState(false)

    const [customerProgress,setCustomerProgress] = useState(0)
    const [customerTotal,setCustomerTotal] = useState(1)
    const [deleteCustomerState,setDeletingCustomerState] = useState('Queued...')

    const [isQueued,setIsQueued] = useState(true)

    useEffect(() => {
        if (deletingCustomers) {
            setShowProgress(true);
            checkCustomerDeletionProgress();
        }
    }, [progress, deletingCustomers]);



    const checkCustomerDeletionProgress = async () => {
        await sleep(3000)

        await api
            .get(`kpn/customer/task?id=${taskId}&type=D`)
            .then((res) => {
                console.log(res.data)
                if(res.data.complete) {
                    setCustomerProgress(res.data.progress)
                    setCustomerTotal(res.data.total)
                    setDeletingCustomerState(res.data.status)

                    dispatch(setFileDeleteState({
                        success:true,
                        error:false,
                        loading:false,
                        message:res.data.message
                    }))
                    setShowProgress(false)
                }
                else if(res.data.queued) {
                    setProgress(progress+0.0000001)
                    setIsQueued(true)
                }
                else {
                    setCustomerProgress(res.data.progress)
                    setCustomerTotal(res.data.total)
                    setDeletingCustomerState(res.data.status)
                    setIsQueued(res.data.queued)
                    var percentage = (res.data.progress / res.data.total) * 100;
                    if(percentage === progress) setProgress(percentage+0.0000001)
                    else setProgress(percentage)
                }
            })
            .catch((err) => {
                setProgress(progress+0.0000001)
                dispatch(setToastType('info'))
                dispatch(setToastValue('Error checking task state...'))
            })



    };



    const handleDelete = async () => {
        dispatch(setFileDeleteState({
            success:false,
            error:false,
            loading:true,
            message:''
        }))
        
        api
            .delete(`kpn/file/adhoc?id=${props.id}`)
            .then((res) => {
                if(res.data.success) {
                    setTaskId(res.data.task_id)
                    setIsQueued(true)
                    dispatch(setFileDeleteState({
                        success:false,
                        error:false,
                        loading:true,
                        message:''
                    }))
                    setDeletingCustomers(true)
                }
                else {
                    dispatch(setToastType('error'))
                    dispatch(setToastValue(res.data.message))
                }

            })
            .catch((err) => {
                dispatch(setToastType('error'))
                dispatch(setToastValue('Something went wrong...'))
            })

    }
    const handleClose = () => {
        if(props.pageSettings) {
            let s = props.pageSettings
            dispatch(getFiles(s[0],s[1],s[2],s[3]))
        }
        else {
            dispatch(getMasterbestand())

        }
        dispatch(setFileDeleteState({
            success:false,
            error:false,
            loading:false,
            message:''
        }))
        onClose();
    }


    React.useEffect(() => {
        if(toastType) toast({
            title:toastValue,
            status: toastType,
            duration: 4000,
            isClosable: true,
        })
        dispatch(setToastType(null))
    })




    return(
        <>
            <IconButton size={props.size} variant='outline' icon={<DeleteIcon color='red.500' />} onClick={() => onOpen()} />
            <Modal isOpen={isOpen} onClose={handleClose} isCentered scrollBehavior="inside">
                <ModalOverlay />
                <ModalContent  bg='primary.500' width='400px'>
                    <ModalHeader>Delete AdHoc File</ModalHeader>
                    <ModalCloseButton />

                    <ModalBody>

                        <Text fontSize='sm' color='textSecondary.500'>Deleting this file will result in all associated customers to be wiped from the database. Please proceed with caution!</Text>
                        {
                            fileDeleteState.error ? 
                            <FadeIn>
                                <Alert fontSize='xs' status='error' borderRadius='6px' marginBottom='20px'>
                                    <AlertIcon />
                                    {fileDeleteState.message}
                                </Alert>
                            </FadeIn>
                            
                            : 
                            <></>
                        }

                        {
                            fileDeleteState.success ? 
                            <FadeIn>
                                <Alert fontSize='xs' status='success' borderRadius='6px' marginBottom='20px'>
                                    <AlertIcon />
                                    {fileDeleteState.message}
                                </Alert>
                            </FadeIn>
                            
                            : 
                            <></>
                        }

                        {showProgress ? 
                            <Box marginTop='20px'>
                                <Text fontSize='xs' color='textSecondary.500'>{deleteCustomerState}   {!isQueued ? `${progress.toFixed(0)}% [${customerProgress} / ${customerTotal}]` : ""}</Text>
                                <Progress colorScheme='blue' size='sm' value={progress} borderRadius='8px' /> 
                            </Box>:
                            <></>
                        }



                    </ModalBody>

                    <ModalFooter>
                        <Button isLoading={fileDeleteState.loading} leftIcon={<DeleteIcon />} variant='solid' marginLeft='10px' onClick={() => handleDelete()}>
                            Delete
                        </Button>
                    </ModalFooter>

                </ModalContent>
            </Modal>
            
        </>
        
    )
}



export default AdHocDeleteModal;