
import React from "react";
import StaffPageWrapper from "./PageWrapper";
import FadeIn from 'react-fade-in'
import Header from '../../components/header/Header'
import DetailsPanel from "../../components/details/DetailsPanel";

import useWindowSize from "../../tools/useWindow";

import { SimpleGrid, Flex, Input, InputGroup, InputLeftAddon, Box, RadioGroup, Radio, Switch, Text, Button } from "@chakra-ui/react";

import './page.css'

const StaffSettings = () => {

    const [windowWidth,windowHeight] = useWindowSize()



    // ====================================== USER PREFERENCES

    let [userData,setUserData] = React.useState({
        email:'',
        firstName:'',
        lastName:'',
        organization:'',
        phone:'',
        phonePrefix:'+31'
    })

    const [emailError,setEmailError] = React.useState(false)
    const [firstNameError,setFirstNameError] = React.useState(false)
    const [lastNameError,setLastNameError] = React.useState(false)
    const [organizationError,setOrganizationError] = React.useState(false)
    const [phoneError,setPhoneError] = React.useState(false)

    const [lang,setLang] = React.useState('0')

    const handleChangeUserData = (event) => {

        event.preventDefault()
        const value=event.target.value
        setUserData({...userData,[event.target.name]:value});
    }
    const handleChangeLang = (v) => setLang(v)



    const saveUserSettings = () => {
        let lower = /[A-Z]/
        let upper = /[a-z]/
        let number = /[0-9]/
        let special = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
        let emailPattern = /[a-zA-Z0-9]+[\.]?([a-zA-Z0-9]+)?[\@][a-z]{3,12}[\.][a-z]{2,5}/g;

        let validInput = true

        if(userData.firstName === "") {
            setFirstNameError(true)
            validInput = false;
        } else setFirstNameError(false);

        if(userData.lastName === "") {
            setLastNameError(true)
            validInput = false;
        } else setLastNameError(false);

        if(userData.organization === "") {
            setOrganizationError(true); 
            validInput = false;
        } else setOrganizationError(false);

        if(!emailPattern.test(userData.email)) {
            setEmailError(true)
            validInput = false;
        } else setEmailError(false);

        if(!number.test(userData.phone) || userData.phone === "") {
            setPhoneError(true)
            validInput = false;
        } else setPhoneError(false);


        if(validInput) {

        }   
    }


    return(
        <StaffPageWrapper page={
            <div style={{overflow:'hidden', paddingBottom:'20px'}}>
                <FadeIn>
                    <Header
                        title="Settings"
                        date={false}
                        stat={false}
                    />
                    <div style={{margin:'20px 2% 10px 2%'}}>
                        <Flex justifyContent='left' alignItems='top' flexWrap='wrap'>
                            <Box margin='5px'>
                                <DetailsPanel 
                                    height={480}
                                    width='320px'
                                    title='Personal'
                                    content={
                                        <>
                                            <Text marginBottom='10px'>User</Text>

                                            <Flex>
                                                <Input 
                                                    value={userData.firstName}
                                                    onChange={handleChangeUserData}
                                                    name='firstName'

                                                    placeholder='First Name' 
                                                    variant='filled' 
                                                    size='md' 
                                                    focusBorderColor='blue.500'
                                                    errorBorderColor='red.300'
                                                    isInvalid={firstNameError}
                                                />
                                                <Input 
                                                    marginLeft='10px'
                                                    value={userData.lastName}
                                                    onChange={handleChangeUserData}
                                                    name='lastName'

                                                    placeholder='Last Name' 
                                                    variant='filled' 
                                                    size='md' 
                                                    focusBorderColor='blue.500'
                                                    errorBorderColor='red.300'
                                                    isInvalid={lastNameError}
                                                />
                                            </Flex>

                                            <Input 
                                                marginTop='10px'
                                                value={userData.organization}
                                                onChange={handleChangeUserData}
                                                name='organization'

                                                placeholder='Organization' 
                                                variant='filled' 
                                                size='md' 
                                                focusBorderColor='blue.500'
                                                errorBorderColor='red.300'
                                                isInvalid={organizationError}
                                            />

                                            <InputGroup>
                                                <InputLeftAddon children={
                                                    <Input 
                                                        value={userData.phonePrefix}
                                                        onChange={handleChangeUserData}
                                                        name='phonePrefix'
                                                        focusBorderColor='blue.500'
                                                        variant='unstyled'

                                                        placeholder='+31' 
                                                

                                                        width='40px'
                                                    />
                                                } marginTop='10px' />
                                                <Input 
                                                    marginTop='10px'
                                                    value={userData.phone}
                                                    onChange={handleChangeUserData}
                                                    name='phone'
                                                    variant='filled' 
                                                    focusBorderColor='blue.500'
                                                    isInvalid={phoneError}
                                                    errorBorderColor='red.300'

                                                    placeholder='Phone number' 
                                                />
                                            </InputGroup>
                                            

                                            <Input 
                                                marginTop="10px"
                                                value={userData.email}
                                                onChange={handleChangeUserData}
                                                name='email'

                                                placeholder='Email' 
                                                variant='filled' 
                                                size='md' 
                                                focusBorderColor='blue.500'
                                                errorBorderColor='red.300'
                                                isInvalid={emailError}
                                            />


                                            <Text marginBottom='10px' marginTop='30px'>Language</Text>


                                            <RadioGroup value={lang} onChange={handleChangeLang} marginTop='20px'>
                                                <Radio value='0' marginLeft='10px'>
                                                    <Box className='gb-lang'/>
                                                </Radio>
                                                <Radio value='1' marginLeft='10px'>
                                                    <Box className='nl-lang'/>
                                                </Radio>
                                            
                                            </RadioGroup>


                                            
                                        </>
                                    }
                                />
                            </Box>
                            
                            <Box margin='5px'>
                                <DetailsPanel 
                                    height={480}
                                    width='320px'
                                    title='System'
                                    content={
                                        <>
                                            <Text marginBottom='10px'>Match contacts by:</Text>

                                            <Flex alignItems='center' marginBottom='5px' marginLeft='10px'>
                                                <Switch />
                                                <Text marginLeft='5px' fontSize='sm'>Phone Number</Text>
                                            </Flex>
                                            <Flex alignItems='center' marginBottom='5px' marginLeft='10px'>
                                                <Switch />
                                                <Text marginLeft='5px' fontSize='sm'>First Name</Text>
                                            </Flex>
                                            <Flex alignItems='center' marginBottom='5px' marginLeft='10px'>
                                                <Switch />
                                                <Text marginLeft='5px' fontSize='sm'>Last Name</Text>
                                            </Flex>
                                            <Flex alignItems='center' marginBottom='5px' marginLeft='10px'>
                                                <Switch />
                                                <Text marginLeft='5px' fontSize='sm'>Address</Text>
                                            </Flex>



                                            <Text marginBottom='10px' marginTop='30px'>Anonymize record IDs after:</Text>
                                            <Input 
                                                width='60%'
                                                type='number'
                                                placeholder="... months"
                                                variant='filled' 
                                                size='md' 
                                                focusBorderColor='blue.500'
                                                errorBorderColor='red.300'
                                            />

                                        
                                        </>
                                    }
                                />
                            </Box>
                            
                            <Box margin='5px'>
                                <DetailsPanel 
                                    height={480}
                                    width='320px'
                                    title='API'
                                    content={
                                        <>
                                            <Text marginBottom='5px'>Update Tk data daily at:</Text>
                                            <Input 
                                                width='60%'
                                                type='time'
                                                variant='filled' 
                                                size='md' 
                                                focusBorderColor='blue.500'
                                                errorBorderColor='red.300'
                                            />
                                            <Text marginTop='20px' marginBottom='5px'>Update LeadDesk data daily at:</Text>
                                            <Input 
                                                width='60%'
                                                type='time'
                                                variant='filled' 
                                                size='md' 
                                                focusBorderColor='blue.500'
                                                errorBorderColor='red.300'
                                            />
                                            <Text marginTop='20px' marginBottom='5px'>Update SalesDock data daily at:</Text>
                                            <Input 
                                                width='60%'
                                                type='time'
                                                variant='filled' 
                                                size='md' 
                                                focusBorderColor='blue.500'
                                                errorBorderColor='red.300'
                                            />


                                        </>
                                    }
                                />

                            </Box>
                        </Flex>
                    </div>


                </FadeIn>
            </div>
        }
        />
    )
}


export default StaffSettings;