
import React, { useState } from "react";
import StaffPageWrapper from "./PageWrapper";
import FadeIn from 'react-fade-in'
import Header from '../../components/header/Header'
import Paginator from '../../components/paginator/Paginator'

import { useEffectOnce } from "../../tools/useEffectOnce";

import { Center, Skeleton, Text, useToast, Box, SimpleGrid } from "@chakra-ui/react";
import { Input, InputGroup, InputLeftElement, Select } from "@chakra-ui/react";
import { useSelector, useDispatch } from "react-redux";


import AdHocPanel from "../../components/panels/AdHocPanel";


import { SearchIcon } from "@chakra-ui/icons";




const adhoc = [
    {
      kpn: true,
      adhoc: true,
      timestamp: '2023-08-16T12:00:00Z',
      voornaam: 'Lotte',
      ahdnr: 123,
      geslacht: 'Vrouw',
      telnr: '06-12345678',
      woonplaats: 'Amsterdam',
      fanaam: 'TechNL',
    },
    {
      kpn: false,
      adhoc: true,
      timestamp: '2023-08-16T13:00:00Z',
      voornaam: 'Daan',
      ahdnr: 456,
      geslacht: 'Man',
      telnr: '06-98765432',
      woonplaats: 'Utrecht',
      fanaam: 'DataBV',
    },
    {
      kpn: true,
      adhoc: true,
      timestamp: '2023-08-16T14:00:00Z',
      voornaam: 'Emma',
      ahdnr: 789,
      geslacht: 'Vrouw',
      telnr: '06-55555555',
      woonplaats: 'Rotterdam',
      fanaam: 'DesignFirm',
    },
    {
      kpn: false,
      adhoc: true,
      timestamp: '2023-08-16T15:00:00Z',
      voornaam: 'Lucas',
      ahdnr: 101,
      geslacht: 'Man',
      telnr: '06-44444444',
      woonplaats: 'Den Haag',
      fanaam: 'InnovateBouw',
    },
    {
      kpn: true,
      adhoc: true,
      timestamp: '2023-08-16T16:00:00Z',
      voornaam: 'Sophie',
      ahdnr: 112,
      geslacht: 'Vrouw',
      telnr: '06-77777777',
      woonplaats: 'Eindhoven',
      fanaam: 'GroenTech',
    },
    {
      kpn: false,
      adhoc: true,
      timestamp: '2023-08-16T17:00:00Z',
      voornaam: 'Milan',
      ahdnr: 213,
      geslacht: 'Man',
      telnr: '06-11111111',
      woonplaats: 'Groningen',
      fanaam: 'InfoSoft',
    },
    {
      kpn: true,
      adhoc: true,
      timestamp: '2023-08-16T18:00:00Z',
      voornaam: 'Isabella',
      ahdnr: 315,
      geslacht: 'Vrouw',
      telnr: '06-22222222',
      woonplaats: 'Nijmegen',
      fanaam: 'TechnoGroup',
    },
    {
      kpn: true,
      adhoc: true,
      timestamp: '2023-08-16T19:00:00Z',
      voornaam: 'Sven',
      ahdnr: 417,
      geslacht: 'Man',
      telnr: '06-88888888',
      woonplaats: 'Tilburg',
      fanaam: 'EcoSolutions',
    },
    {
      kpn: false,
      adhoc: true,
      timestamp: '2023-08-16T20:00:00Z',
      voornaam: 'Fleur',
      ahdnr: 518,
      geslacht: 'Vrouw',
      telnr: '06-55555555',
      woonplaats: 'Amersfoort',
      fanaam: 'InnovaBouw',
    },
    {
      kpn: true,
      adhoc: true,
      timestamp: '2023-08-16T21:00:00Z',
      voornaam: 'Sem',
      ahdnr: 619,
      geslacht: 'Man',
      telnr: '06-99999999',
      woonplaats: 'Breda',
      fanaam: 'WebTech',
    },
    {
      kpn: true,
      adhoc: true,
      timestamp: '2023-08-16T22:00:00Z',
      voornaam: 'Anna',
      ahdnr: 722,
      geslacht: 'Vrouw',
      telnr: '06-66666666',
      woonplaats: 'Haarlem',
      fanaam: 'NXTMedia',
    },
    {
      kpn: true,
      adhoc: true,
      timestamp: '2023-08-16T23:00:00Z',
      voornaam: 'Thijs',
      ahdnr: 834,
      geslacht: 'Man',
      telnr: '06-44444444',
      woonplaats: 'Zwolle',
      fanaam: 'DigitalSolutions',
    },
    {
      kpn: false,
      adhoc: true,
      timestamp: '2023-08-17T00:00:00Z',
      voornaam: 'Eva',
      ahdnr: 946,
      geslacht: 'Vrouw',
      telnr: '06-11111111',
      woonplaats: 'Arnhem',
      fanaam: 'InnovateMedia',
    },
    {
      kpn: true,
      adhoc: true,
      timestamp: '2023-08-17T01:00:00Z',
      voornaam: 'Max',
      ahdnr: 1057,
      geslacht: 'Man',
      telnr: '06-88888888',
      woonplaats: 'Enschede',
      fanaam: 'EcoInnovations',
    },
    {
      kpn: false,
      adhoc: true,
      timestamp: '2023-08-17T02:00:00Z',
      voornaam: 'Julia',
      ahdnr: 1168,
      geslacht: 'Vrouw',
      telnr: '06-77777777',
      woonplaats: 'Maastricht',
      fanaam: 'TechSolutions',
    },
  ];
  
  // Continue adding 10 more entries following the same structure as the examples above.
  
const adhocAmount = 15


const StaffCustomersAdHoc = () => {
    const toast = useToast()
    const dispatch = useDispatch()

    const { toastType, toastValue } = useSelector(state => state.staffCustomer)

    const [searchTerm,setSearchTerm] = useState('')


    const handleSearchChange = (e) => setSearchTerm(e.target.value)

    const PageContent = () => adhoc.map((customer) => <AdHocPanel 
        kpn={customer.kpn}
        adhoc={customer.adhoc}
        timestamp={customer.timestamp}

        firstName={customer.voornaam}

        ahdnr={customer.ahdnr}
        gender={customer.geslacht}
        phone={customer.telnr}
        location={customer.woonplaats}
        company={customer.fanaam}
    />)


    return (
        <FadeIn>
            <Header
                title=""
                date={false}
                stat={true}
                statValue={adhocAmount}
                statLabel="customers in database"
                rightElements={<>
                    <InputGroup size='sm' width='200px' marginLeft='10px'>
                        <Input 
                            bg='white'
                            borderRadius='8px'
                            placeholder='Search...' 
                            value={searchTerm}
                            variant='filled'
                            focusBorderColor="blue.500"
                            onChange={handleSearchChange}
                        />
                    
                        <InputLeftElement
                            pointerEvents='none'
                            children={<SearchIcon size="18px" color="black"/>}
                        />
                    </InputGroup>  
                </>}
            />
            <Box className='content-container'>
                <SimpleGrid
                    minChildWidth='300px'
                    spacing='10px'
                >
                    <PageContent />
                </SimpleGrid>
            </Box>
        </FadeIn>
    )


}


export default StaffCustomersAdHoc;