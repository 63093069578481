import { Box, IconButton, Flex, Text, Divider } from "@chakra-ui/react";
import { Menu, MenuItem, MenuList, MenuButton } from "@chakra-ui/react";


import { DeleteIcon, CloseIcon, DownloadIcon } from "@chakra-ui/icons";
import AdHocDeleteModal from "../modals/AdHocDeleteModal";

import { HiOutlineInboxIn, HiOutlineClock, HiOutlineDocumentText } from "react-icons/hi";

import './panels.css'
import { Tooltip } from "@chakra-ui/react";
import KpnDeleteModal from "../modals/KpnDeleteModal";



const getCleanDate = (date) => {
    let [dateStr,timeStr] = date.split('T')

    let [year,month,day] = dateStr.split('-')



    return `${day}/${month}/${year} ${timeStr.substring(0,5)}`
}
const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }

const MasterImportPanel = (props) => {


    const iconColor = () => {
        if(props.fileTypeId === 'K' || props.fileTypeId === 'D' ) {
            return '#32b4ff'
        }
        else if(props.fileTypeId === 'A') {
            return '#009900'
        }
        else {
            return '#000000'
        }
    }


    const DeleteButton = () => {
        if(props.fileTypeId === 'K' || props.fileTypeId === 'D') return <KpnDeleteModal type={props.fileTypeId} size='sm' id={props.id} />
        else if(props.fileTypeId === 'A') return <AdHocDeleteModal size='sm' id={props.id} />
    }

    return(
        <Box className='import-panel'>
            <Flex justifyContent='space-between' alignItems='top' marginBottom='20px'>
                <Flex alignItems='center' justifyContent='left'>
                    
                    <Box className='icon-container'>
                        <HiOutlineInboxIn size='28px' color={iconColor()} />
                    </Box>             
           
                    <Box>
                        <Tooltip label={props.name} hasArrow bg='white' color='black'>
                            <Text marginLeft='10px' maxW='160px' fontSize='xs' noOfLines={1}>{props.name}</Text>
                        </Tooltip>
                        <Text marginLeft='10px' fontSize='xs' noOfLines={1} color='textSecondary.500'>by {props.user}</Text>
                    </Box>
                </Flex>
                <Flex alignItems='center' justifyContent='right'>
                    <IconButton marginRight='6px' size='sm' variant='outline' icon={<DownloadIcon />} onClick={() => openInNewTab(props.file)} />
                    <DeleteButton />
                </Flex>
 
            </Flex>
            
            <Divider marginBottom='14px' />

            <Flex alignItems='center' marginBottom='10px'>
                <HiOutlineDocumentText size='22px' color='#7f7f7f' />
                <Text fontSize='xs' marginLeft='5px' color='textSecondary.500'>{props.fileType} file - [{props.count} new customers]</Text>
            </Flex>


            <Flex alignItems='center' marginBottom='10px'>
                <HiOutlineClock size='22px' color='#7f7f7f' />
                <Text fontSize='xs' marginLeft='5px' color='textSecondary.500'>{getCleanDate(props.timestamp)}</Text>
            </Flex>
        </Box>
    )
}


export default MasterImportPanel;