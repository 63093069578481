

import { Flex, Text, Wrap } from "@chakra-ui/react";
import useWindowSize from "../../tools/useWindow";

import './header.css'

const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];



const Header = (props) => {
    const [windowWidth,windowHeight] = useWindowSize();


    const getDate = () => {
        let today = new Date()
        let dd = String(today.getDate());
        let mm = months[today.getMonth()]
        let yyyy = today.getFullYear();
        
        return `${dd} ${mm}, ${yyyy}`
    }

    if(windowWidth < 720) {
        return (
            <>
                <Flex className="page-header-container">
                    <Text color='textMain.500' fontSize='lg'>{props.title}</Text>
                    {props.date ?                             
                        <Text color='textSecondary.500' fontSize='sm'>{getDate()}</Text> :
                        <></>
                    }
                    {props.stat ? 
                        <Flex>
                            <Text color='textMain.500' fontSize='xs'>{props.statValue}</Text>
                            <Text color='textSecondary.500' fontSize='xs'>&nbsp;{props.statLabel}</Text>
                        </Flex>:
                        <></>
                    }
                </Flex>
                {props.rightElements ?
                <Wrap justify='right' className='mobile-page-header-container'>
                    {props.rightElements}
                </Wrap> : 
                <></>
                }
                
            </>
        )
    }
    else {
        return (
            <Flex className="page-header-container">
                <Text color='textMain.500' fontSize='lg'>{props.title}</Text>
    
                <Flex className="page-header_right-adornment-container">
                    {props.date ?                             
                        <Text color='textSecondary.500' fontSize='sm'>{getDate()}</Text> :
                        <></>
                    }
                    {props.stat ? 
                        <Flex>
                            <Text color='textMain.500' fontSize='xs'>{props.statValue}</Text>
                            <Text color='textSecondary.500' fontSize='xs'>&nbsp;{props.statLabel}</Text>
                        </Flex>:
                        <></>
                    }
                    {props.rightElements}
                </Flex>
            </Flex>
        )
    }


}

export default Header;