
import { Box, Flex, IconButton, Text, Menu, MenuButton, MenuList, MenuItem, Divider, Tag, Tooltip } from "@chakra-ui/react";

import './panels.css'

import { HiOutlineUserCircle, HiOutlineLocationMarker, HiOutlinePhone, HiOutlineOfficeBuilding, HiOutlinePuzzle, HiOutlineUsers } from "react-icons/hi";
import KpnDetailModal from "../modals/KpnDetailModal";






const CustomerPanel = (props) => {


    return(
        <Box className='customer-panel'>
            <Flex justifyContent='space-between' alignItems='top' marginBottom='20px'>
                <Flex alignItems='center' justifyContent='left'>
                    <Box className='icon-container'>
                        <HiOutlineOfficeBuilding size='28px' color='#32b4ff' />
                    </Box>      

                    <Box>
                        <Text marginLeft='10px' maxW='200px' fontSize='xs' noOfLines={1}>{props.company}</Text>
                        <Text marginLeft='10px' fontSize='xs' noOfLines={1} color='textSecondary.500'>{props.selectionId}</Text>

                    </Box>

                </Flex>
                <KpnDetailModal 
                    kpn={props.kpn}
                    adhoc={props.adhoc} 
                />
            </Flex>
            <Divider marginBottom='14px' />
            <Flex alignItems='center' marginBottom='10px'>
                <HiOutlinePuzzle size='20px' color={props.adhoc ? '#48BB78' : '#F56565'} />
                <Text fontSize='xs' marginLeft='5px' color={props.adhoc ? '#48BB78' : '#F56565'}>{props.adhoc ? 'AdHoc profile linked!' : 'No AdHoc profile linked!'}</Text>
            </Flex>
            <Flex alignItems='center' marginBottom='10px'>
                <HiOutlineUsers size='20px' color='#7f7f7f' />
                <Text fontSize='xs' marginLeft='5px' color='textSecondary.500'>{props.firstName ? props.firstName : "..."} {props.lastName ? props.lastName : "..."}</Text>
            </Flex>
            <Flex alignItems='center' marginBottom='10px'>
                <HiOutlineLocationMarker size='20px' color='#7f7f7f' />
                <Text fontSize='xs' marginLeft='5px' color='textSecondary.500'>{props.location}</Text>
            </Flex>
            <Flex alignItems='center' marginBottom='10px'>
                <HiOutlinePhone size='20px' color='#7f7f7f' />
                <Text fontSize='xs' marginLeft='5px' color='textSecondary.500'>{props.phone}</Text>
            </Flex>
        </Box>
    )
}

export default CustomerPanel;

