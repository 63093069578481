import { createSlice } from "@reduxjs/toolkit";
import { api } from "./api";

export const initialState = {
    loading: false,
    error:false,

    users:[],
    userAmount:0,

    page: 1,
    maxPage:1,
    search:"",
    filter:'0',

    toastType:null,
    toastValue:"Success!",


    addStaff: {
        loading: false,
        error: false,
        errorText: "Something went wrong...",
        success: false,
    },
}

const staffUserSlice = createSlice({
    name:'staffNetto',
    initialState,
    reducers: {

        setLoading: (state, action) => {state.loading = action.payload},
        setError: (state, action) => {state.error = action.payload},

        setUserAmount: (state, action) => {state.userAmount = action.payload},
        setUsers: (state, action) => {state.users = action.payload},

        nextPage: (state) => {state.page++},
        previousPage: (state) => {state.page--;},
    
        setMaxPage: (state,action) => {state.maxPage = action.payload},
        setPageSize: (state,action) => {state.pageSize = action.payload},
    
        setSearch: (state, action) => {
            state.search = action.payload;
            state.page = 1;
        },
        setFilter: (state, action) => {state.filter = action.payload},

        setAddStaff: (state, action) => {state.addStaff = action.payload;},

        setToastValue: (state, action) => {state.toastValue = action.payload},
        setToastType: (state, action) => {state.toastType = action.payload}
    }
})

export const {

    setLoading, setError, nextPage, previousPage, setMaxPage, setPageSize,
    setToastType, setToastValue, setSearch, setFilter, setUserAmount, setUsers, setAddStaff

} = staffUserSlice.actions

export default staffUserSlice.reducer;




export function getUsers(page,pageSize,search,filter) {
    return async (dispatch) => {
        dispatch(setLoading(true))
        api
            .get(`auth/staff/users?page=${page}&search=${search}&filter=${filter}&page_size=${pageSize}`)
            .then((res) => {
                dispatch(setUserAmount(res.data.total_amount))
                dispatch(setMaxPage(res.data.max_page))
                dispatch(setUsers(res.data.users))

                dispatch(setLoading(false))
                dispatch(setError(false))
            })
            .catch((err) => {
                dispatch(setLoading(false))
                dispatch(setError(true))
            })
    }
}




export function handleAddStaff(data) {
    return async (dispatch) => {
        dispatch(setAddStaff({
            loading: true,
            error: false,
            errorText: "Something went wrong...",
            success: false,
        }))
        api
            .post("auth/staff/signup/", data)
            .then((res) => {
                if(res.data.success) {
                    dispatch(setAddStaff({
                        loading: false,
                        error: false,
                        errorText: "Something went wrong...",
                        success: true,
                    }))
                }
                else {
                    dispatch(setAddStaff({
                        loading: false,
                        error: true,
                        errorText: res.data.message,
                        success: false,
                    }))
                }
                
            })
            .catch((err) => {
                dispatch(setAddStaff({
                    loading: false,
                    error: true,
                    errorText: "Something went wrong...",
                    success: false,
                }))
            })
    }
}

export function deleteUser(id,page,pageSize,search,filter) {
    return async (dispatch) => {
        dispatch(setLoading(true))
        api
            .delete(`auth/staff/users?id=${id}`)
            .then((res) => {
                dispatch(setLoading(false))
                dispatch(getUsers(page,pageSize,search,filter))
                
                dispatch(setToastType('success'))
                dispatch(setToastValue(res.data.message))

            })
            .catch((err) => {
                dispatch(setLoading(false))
                dispatch(setToastType('error'))
                dispatch(setToastValue('Something went wrong...'))
            })
    }

}