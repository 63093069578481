

import Main from "./Main";


import { ChakraProvider } from "@chakra-ui/react";
import theme from "./theme";

import { Provider } from 'react-redux'
import { store } from './store'

function App() {
  return (
    <Provider store={store}>
      <ChakraProvider theme={theme}>
        <Main/>
      </ChakraProvider>
    </Provider>

  );
}

export default App;
