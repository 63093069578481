
import { useState } from "react"

import { useToast, useDisclosure } from "@chakra-ui/react"
import { Radio, RadioGroup, Button  } from "@chakra-ui/react"
import { DownloadIcon, AddIcon } from "@chakra-ui/icons"

import { Modal, ModalBody, ModalFooter, ModalContent, ModalOverlay, ModalCloseButton, ModalHeader } from "@chakra-ui/react"

import { useSelector, useDispatch } from "react-redux"

import FileUpload from "../fileUpload/FileUpload"

const NettoImportModal = () => {

    const toast = useToast()
    const { isOpen, onOpen, onClose } = useDisclosure()
    const { loading } = useSelector(state => state.staffMaster)

    const [importType,setImportType] = useState('0')
    const handleImportTypeChange = (v) => setImportType(v)



    const handleOpen = () => {
        onOpen();
    }
    const handleAdd = () => {
    
    }
    const handleClose = () => {
        onClose();
    }


    return(
        <>
        <Button
            bg='secondary.500'
            borderRadius='8px'
            focusBorderColor="blue.500"
            size='sm'
            marginRight='5px'
            leftIcon={<DownloadIcon />}
            onClick={handleOpen}
        >
            Import Netto files
        </Button>

        <Modal isOpen={isOpen} onClose={handleClose} isCentered scrollBehavior="inside">
            <ModalOverlay />
            <ModalContent  bg='primary.500' width='400px'>
                <ModalHeader>Add Netto Files</ModalHeader>
                <ModalCloseButton />

                <ModalBody>
                    <RadioGroup value={importType} onChange={handleImportTypeChange} marginBottom='20px'>
                        <Radio value='0' marginRight='10px'>
                            KZ
                        </Radio>
                        <Radio value='1' marginRight='10px'>
                            KZ Cancellation
                        </Radio>
                        <Radio value='2'>
                            KZ Mob
                        </Radio>
                    </RadioGroup>

                    <FileUpload defaultText='Select file' />



                </ModalBody>

                <ModalFooter>
                    <Button isLoading={loading} leftIcon={<AddIcon />} variant='solid' marginLeft='10px' onClick={() => handleAdd()}>
                        Add
                    </Button>
                </ModalFooter>

            </ModalContent>
        </Modal>
        
        </>
    )
}

export default NettoImportModal;