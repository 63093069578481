import React from "react";
import FadeIn from 'react-fade-in'

import { Heading, Text, VStack } from "@chakra-ui/react"


import './navbar.css'

const Navbar = (props) => {

  

    return(
        <VStack backgroundColor='primary.500' width='200px' height='100vh'>
            <VStack height='120px' marginTop='40px'>
                <div className='navbar_logo' />
            </VStack>

            {props.menuItems}

        </VStack>
    )
}

export default Navbar;