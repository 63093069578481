import React from "react";


import { Flex, IconButton, useDisclosure } from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";

import { Drawer, DrawerOverlay, DrawerContent, DrawerHeader, DrawerCloseButton, DrawerBody, Center, Divider, Heading, Text, Box } from "@chakra-ui/react";

import './navbar.css'


const MobileNavbar = (props) => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const btnRef = React.useRef()




    return(
        <Flex className='mobile-navbar-container' alignItems='center' justifyContent='space-between'>
            <Flex alignItems='center' justifyContent='center' marginLeft='20px'>
                <div className='navbar_logo-small' />
            </Flex>

            <IconButton 
                ref={btnRef}
                onClick={onOpen}
                variant='ghost'
                icon={<ChevronDownIcon fontSize='2xl' />}
                marginRight='20px'
            />
            <Drawer
                isOpen={isOpen}
                placement='top'
                onClose={onClose}
                finalFocusRef={btnRef}
            >
                <DrawerOverlay />
                <DrawerContent
                    bg='primary.500'
                >
                    <DrawerCloseButton />
                    <DrawerHeader>
                        <Center marginTop='30px'>
                            <div className='navbar_logo' />
                        </Center>
                    
                    </DrawerHeader>

                    <DrawerBody>
                        {props.menuItems}
                    </DrawerBody>

               
                </DrawerContent>
            </Drawer>
        </Flex>
    )
}


export default MobileNavbar;