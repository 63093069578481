import { useState } from "react";

import StaffPageWrapper from "./PageWrapper";
import FadeIn from 'react-fade-in'
import Header from '../../components/header/Header'

import { Tabs, Tab, TabList,  } from "@chakra-ui/react";

import StaffTeleknowledge from "./ApiTeleknowledge";
import StaffLeadDesk from "./ApiLeadDesk"
import StaffSalesDock from "./ApiSalesDock";

const StaffApi = () => {

    const [tab,setTab] = useState(0)
    const handleTabChange = (i) => setTab(i)



    const getPageTitle = () => {
        switch(tab) {
            case 0: return 'Teleknowledge Customers';
            case 1: return 'LeadDesk Calls';
            case 2: return 'SalesDock Sales';

            default: return 'Teleknowledge Customers';
        }
    }
    const PageContent = () => {
        switch(tab) {
            case 0: return <StaffTeleknowledge />
            case 1: return <StaffLeadDesk />
            case 2: return <StaffSalesDock />

            default: return <StaffTeleknowledge />
        }
    }

    return(
        <StaffPageWrapper page={
            <div style={{overflow:'hidden', paddingBottom:'20px'}}>
                <FadeIn>
                    <Header
                        title={getPageTitle()}
                        date={false}
                        stat={false}
                        rightElements={
                            <Tabs index={tab} onChange={handleTabChange} variant='solid-rounded' colorScheme='cyan'>
                                <TabList>
                                    <Tab fontSize='xs' value='0' borderRadius='6px'>Teleknowledge</Tab>
                                    <Tab fontSize='xs' value='1' borderRadius='6px'>LeadDesk</Tab>
                                    <Tab fontSize='xs' value='2' borderRadius='6px'>SalesDock</Tab>
                                </TabList>
                            </Tabs>
                        }
                    />
                    <PageContent />
                </FadeIn>
            </div>
        }
        />
    )
}


export default StaffApi;