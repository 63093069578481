
import React from "react";
import StaffPageWrapper from "./PageWrapper";
import FadeIn from 'react-fade-in'
import Header from '../../components/header/Header'
import Datagrid from "../../components/table/Datagrid";

import { useDisclosure, Tr, Td, Tag } from "@chakra-ui/react";
import { Input, InputGroup, InputLeftAddon, InputRightElement } from "@chakra-ui/react";
import { Text, Button, Flex } from "@chakra-ui/react";
import { Menu, MenuButton, IconButton, MenuList, MenuItem } from "@chakra-ui/react";
import { Modal, ModalBody, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalFooter } from "@chakra-ui/react";

import { AddIcon, DeleteIcon } from "@chakra-ui/icons";
import { HiOutlineUserCircle } from "react-icons/hi";

const TABLE_HEAD = [
    {
        label:'',
        width:'20px'
    },
    {
        label:'Email'
    },
    {
        label:'Name'
    },
    {
        label:'Phone'
    },
    {
        label:'Organization'
    },
    {
        label:'Role'
    },
    {
        label:'',
    }
]
const TABLE_FILTERS = [
    {
        label:'All',
        value:'0'
    },
    {
        label:'Staff',
        value:'1'
    }
]

const userAmount = 15
const users = [
    {
      email: 'alice@example.com',
      first_name: 'Alice',
      last_name: 'Johnson',
      phone_number: '123-456-7890',
      organization: 'TechNL',
      is_staff: true,
      id: 1,
    },
    {
      email: 'bob@example.com',
      first_name: 'Bob',
      last_name: 'Smith',
      phone_number: '987-654-3210',
      organization: 'DataBV',
      is_staff: false,
      id: 2,
    },
    {
      email: 'charlie@example.com',
      first_name: 'Charlie',
      last_name: 'Brown',
      phone_number: '555-555-5555',
      organization: 'DesignFirm',
      is_staff: true,
      id: 3,
    },
    {
      email: 'david@example.com',
      first_name: 'David',
      last_name: 'Lee',
      phone_number: '444-444-4444',
      organization: 'InnovateBouw',
      is_staff: false,
      id: 4,
    },
    {
      email: 'eva@example.com',
      first_name: 'Eva',
      last_name: 'Williams',
      phone_number: '777-777-7777',
      organization: 'GroenTech',
      is_staff: true,
      id: 5,
    },
];
  



const AddStaffModal = (props) => {
    const { isOpen, onOpen, onClose } = useDisclosure()


    const [emailError,setEmailError] = React.useState(false)
    const [passwordError,setPasswordError] = React.useState(false)
    const [firstNameError,setFirstNameError] = React.useState(false)
    const [lastNameError,setLastNameError] = React.useState(false)
    const [organizationError,setOrganizationError] = React.useState(false)
    const [phoneError,setPhoneError] = React.useState(false)





    let [userData,setUserData] = React.useState({
        email:'',
        password:'',
        firstName:'',
        lastName:'',
        organization:'',
        phone:'',
        phonePrefix:'+31'
    })
    const handleChangeUserData = (event) => {

        event.preventDefault()
        const value=event.target.value
        setUserData({...userData,[event.target.name]:value});
    }


    const [showPassword, setShowPassword] = React.useState(false);
    const handleClickShowPassword = () => setShowPassword(!showPassword);

    const add = () => {
        let lower = /[A-Z]/
        let upper = /[a-z]/
        let number = /[0-9]/
        let special = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
        let emailPattern = /[a-zA-Z0-9]+[\.]?([a-zA-Z0-9]+)?[\@][a-z]{3,12}[\.][a-z]{2,5}/g;

        let validInput = true

        if(userData.firstName === "") {
            setFirstNameError(true)
            validInput = false;
        } else setFirstNameError(false);

        if(userData.lastName === "") {
            setLastNameError(true)
            validInput = false;
        } else setLastNameError(false);

        if(userData.organization === "") {
            setOrganizationError(true); 
            validInput = false;
        } else setOrganizationError(false);

        if(!emailPattern.test(userData.email)) {
            setEmailError(true)
            validInput = false;
        } else setEmailError(false);

        if(!lower.test(userData.password) || !upper.test(userData.password) || !number.test(userData.password) || !special.test(userData.password)) {
            setPasswordError(true)
            validInput = false;
        } else setPasswordError(false);

        if(!number.test(userData.phone) || userData.phone === "") {
            setPhoneError(true)
            validInput = false;
        } else setPhoneError(false);


        if(validInput) {
  
        }   
    }

    const close = () => {
        onClose();
        
    }



    return(
        <>
        <Button leftIcon={<AddIcon />} bg='primary.500' variant='solid' marginLeft='10px' onClick={onOpen}>
            Staff
        </Button>

        <Modal isOpen={isOpen} onClose={close} isCentered>
            <ModalOverlay />
            <ModalContent  bg='primary.500' width='360px'>
                <ModalHeader>Add Staff User</ModalHeader>
                <ModalCloseButton />

                <ModalBody>
                    <Text fontSize='sm' color='textSecondary.500' marginBottom='20px'>
                        Staff users will have full admin rights
                    </Text>

                    <Flex>
                        <Input 
                            value={userData.firstName}
                            onChange={handleChangeUserData}
                            name='firstName'

                            placeholder='First Name' 
                            variant='filled' 
                            size='md' 
                            focusBorderColor='blue.500'
                            errorBorderColor='red.300'
                            isInvalid={firstNameError}
                        />
                        <Input 
                            marginLeft='10px'
                            value={userData.lastName}
                            onChange={handleChangeUserData}
                            name='lastName'

                            placeholder='Last Name' 
                            variant='filled' 
                            size='md' 
                            focusBorderColor='blue.500'
                            errorBorderColor='red.300'
                            isInvalid={lastNameError}
                        />
                    </Flex>

                    <Input 
                        marginTop='10px'
                        value={userData.organization}
                        onChange={handleChangeUserData}
                        name='organization'

                        placeholder='Organization' 
                        variant='filled' 
                        size='md' 
                        focusBorderColor='blue.500'
                        errorBorderColor='red.300'
                        isInvalid={organizationError}
                    />

                    <InputGroup>
                        <InputLeftAddon children={
                            <Input 
                                value={userData.phonePrefix}
                                onChange={handleChangeUserData}
                                name='phonePrefix'
                                focusBorderColor='blue.500'
                                variant='unstyled'

                                placeholder='+31' 
                          

                                width='40px'
                            />
                        } marginTop='10px' />
                        <Input 
                            marginTop='10px'
                            value={userData.phone}
                            onChange={handleChangeUserData}
                            name='phone'
                            variant='filled' 
                            focusBorderColor='blue.500'
                            isInvalid={phoneError}
                            errorBorderColor='red.300'

                            placeholder='Phone number' 
                        />
                    </InputGroup>
                    

                    <Input 
                        marginTop="10px"
                        value={userData.email}
                        onChange={handleChangeUserData}
                        name='email'

                        placeholder='Email' 
                        variant='filled' 
                        size='md' 
                        focusBorderColor='blue.500'
                        errorBorderColor='red.300'
                        isInvalid={emailError}
                    />
                    <InputGroup size='md' marginTop="10px">
                        <Input
                            value={userData.password}
                            onChange={handleChangeUserData}
                            name='password'

                            
                            pr='4.5rem'
                            type={showPassword ? 'text' : 'password'}
                            placeholder='Password'
                            variant='filled'

                            focusBorderColor='blue.500'
                            errorBorderColor='red.300'
                            isInvalid={passwordError}
                        />
                        <InputRightElement width='4.5rem'>
                            <Button h='1.75rem' size='sm' onClick= {handleClickShowPassword}>
                                {showPassword ? 'Hide' : 'Show'}
                            </Button>
                        </InputRightElement>
                    </InputGroup>    

                    <Text fontSize='sm' color='#65656B' marginTop='20px'>
                        Password must contain:
                    </Text>         
                    <Text fontSize='sm' color='#65656B'>
                        &emsp;- upper & lower case characters
                    </Text>      
                    <Text fontSize='sm' color='#65656B'>
                        &emsp;- special character
                    </Text>     
                    <Text fontSize='sm' color='#65656B'>
                        &emsp;- number
                    </Text>  
                    
                </ModalBody>

                <ModalFooter>
                    <Button 
                        leftIcon={<AddIcon />} 
                        onClick={() => add()}
                        isLoading={false}
                    >
                        Add
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
        </>

    )
}



const StaffUsers = () => {

    const TableContent = () => users.map((user) => <Tr>
        <Td><HiOutlineUserCircle color='black' size='20px' /></Td>
        <Td><Text fontSize='xs' noOfLines={1}>{user.email}</Text></Td>
        <Td><Text fontSize='xs' noOfLines={1}>{`${user.first_name} ${user.last_name}`}</Text></Td>
        <Td><Text fontSize='xs' noOfLines={1}>{user.phone_number}</Text></Td>
        <Td><Tag><Text fontSize='xs' noOfLines={1}>{user.organization}</Text></Tag></Td>
        <Td><Tag><Text fontSize='xs' noOfLines={1}>{user.is_staff ? "STAFF" : "AGENT"}</Text></Tag></Td>
        <Td>
            <Menu>
                <MenuButton
                    as={IconButton}
                    aria-label='Options'
                    size='xs'
                    icon={<DeleteIcon color='red.500' />}
                    variant='outline'
                />
                <MenuList bg='primary.500'>
                    <MenuItem color="red.400">
                        Delete Permanently
                    </MenuItem>
                    <MenuItem>
                        Cancel
                    </MenuItem>
                </MenuList>
            </Menu>
        </Td>
    </Tr>)






    return(
        <StaffPageWrapper page={
            <div style={{overflow:'hidden', paddingBottom:'20px'}}>
                <FadeIn>
                    <Header
                        title="Users"
                        date={false}
                        stat={true}
                        statValue={userAmount}
                        statLabel="files in database"
                        rightElements={
                            <>
                                <AddStaffModal />
                            </>
                        }
                    />
                    <Datagrid 
                        tableHeader={TABLE_HEAD}
                        tableFilter={TABLE_FILTERS}
                        tableContent={<TableContent />}

                        loading={false}
                        error={false}

                        results={users}
                        page={1}
                        maxPage={1}
                    />
                </FadeIn>
            </div>
        }
        />
    )
}


export default StaffUsers;