import { configureStore } from "@reduxjs/toolkit";

import staffAuthReducer from './slices/staff/auth'
import staffMasterReducer from './slices/staff/master'
import staffKpnReducer from './slices/staff/kpn'
import staffNettoReducer from './slices/staff/netto'
import staffUserReducer from './slices/staff/users'
import staffTeleknowledgeSlice from './slices/staff/apiTeleknowledge'
import staffLeadDeskSlice from './slices/staff/apiLeadDesk'
import staffSalesDockSlice from './slices/staff/apiSalesDock'
import staffCustomerSlice from './slices/staff/customer'
import staffSaleSlice from './slices/staff/sale'

import staffFileSlice from './slices/staff/files'
import StaffTaskSlice from "./slices/staff/tasks";

export const store = configureStore({
    reducer: {

        // STAFF REDUCERS
        staffAuth:staffAuthReducer,
        staffMaster:staffMasterReducer,
        staffKpn:staffKpnReducer,
        staffNetto:staffNettoReducer,
        staffUser:staffUserReducer,
        staffTeleknowledge:staffTeleknowledgeSlice,
        staffLeadDesk:staffLeadDeskSlice,
        staffSalesDock:staffSalesDockSlice,
        staffCustomer:staffCustomerSlice,
        staffSale:staffSaleSlice,
        staffFile:staffFileSlice,
        staffTask:StaffTaskSlice
        
    }
})