
import React, { useState } from "react";
import FadeIn from 'react-fade-in'
import Header from '../../components/header/Header'


import { Center, Skeleton, Text, Box, SimpleGrid } from "@chakra-ui/react";
import { Input, InputGroup, InputLeftElement, Select } from "@chakra-ui/react";


import CustomerPanel from "../../components/panels/CustomerPanel";


import { HiOutlineFilter } from "react-icons/hi";
import { SearchIcon, WarningIcon } from "@chakra-ui/icons";




const customerAmount = 15
const customers = [
    {
      adhoc: false,
      kpn: true,
      timestamp: '2023-08-16T12:00:00Z',
      voornaam: 'Lotte',
      achternaam: 'Jansen',
      selectie_id: 1,
      geslacht: 'Vrouw',
      tel_1: '06-12345678',
      wpl: 'Amsterdam',
      bedrijfsnaam: 'TechNL',
    },
    {
      adhoc: true,
      kpn: true,
      timestamp: '2023-08-16T13:00:00Z',
      voornaam: 'Daan',
      achternaam: 'De Vries',
      selectie_id: 2,
      geslacht: 'Man',
      tel_1: '06-98765432',
      wpl: 'Utrecht',
      bedrijfsnaam: 'DataBV',
    },
    {
      adhoc: true,
      kpn: true,
      timestamp: '2023-08-16T14:00:00Z',
      voornaam: 'Emma',
      achternaam: 'Bakker',
      selectie_id: 3,
      geslacht: 'Vrouw',
      tel_1: '06-55555555',
      wpl: 'Rotterdam',
      bedrijfsnaam: 'DesignFirm',
    },
    {
      adhoc: false,
      kpn: true,
      timestamp: '2023-08-16T15:00:00Z',
      voornaam: 'Lucas',
      achternaam: 'Van Dijk',
      selectie_id: 4,
      geslacht: 'Man',
      tel_1: '06-44444444',
      wpl: 'Den Haag',
      bedrijfsnaam: 'InnovateBouw',
    },
    {
      adhoc: true,
      kpn: true,
      timestamp: '2023-08-16T16:00:00Z',
      voornaam: 'Sophie',
      achternaam: 'Janssen',
      selectie_id: 5,
      geslacht: 'Vrouw',
      tel_1: '06-77777777',
      wpl: 'Eindhoven',
      bedrijfsnaam: 'GroenTech',
    },
    {
      adhoc: true,
      kpn: true,
      timestamp: '2023-08-16T17:00:00Z',
      voornaam: 'Milan',
      achternaam: 'Smit',
      selectie_id: 6,
      geslacht: 'Man',
      tel_1: '06-11111111',
      wpl: 'Groningen',
      bedrijfsnaam: 'InfoSoft',
    },
    {
      adhoc: false,
      kpn: true,
      timestamp: '2023-08-16T18:00:00Z',
      voornaam: 'Isabella',
      achternaam: 'Mulder',
      selectie_id: 7,
      geslacht: 'Vrouw',
      tel_1: '06-22222222',
      wpl: 'Nijmegen',
      bedrijfsnaam: 'TechnoGroup',
    },
    {
      adhoc: true,
      kpn: true,
      timestamp: '2023-08-16T19:00:00Z',
      voornaam: 'Sven',
      achternaam: 'Visser',
      selectie_id: 8,
      geslacht: 'Man',
      tel_1: '06-88888888',
      wpl: 'Tilburg',
      bedrijfsnaam: 'EcoSolutions',
    },
    {
      adhoc: false,
      kpn: true,
      timestamp: '2023-08-16T20:00:00Z',
      voornaam: 'Fleur',
      achternaam: 'Boer',
      selectie_id: 9,
      geslacht: 'Vrouw',
      tel_1: '06-55555555',
      wpl: 'Amersfoort',
      bedrijfsnaam: 'InnovaBouw',
    },
    {
      adhoc: true,
      kpn: true,
      timestamp: '2023-08-16T21:00:00Z',
      voornaam: 'Sem',
      achternaam: 'Kok',
      selectie_id: 10,
      geslacht: 'Man',
      tel_1: '06-99999999',
      wpl: 'Breda',
      bedrijfsnaam: 'WebTech',
    },
    {
      adhoc: false,
      kpn: true,
      timestamp: '2023-08-16T22:00:00Z',
      voornaam: 'Anna',
      achternaam: 'Maas',
      selectie_id: 11,
      geslacht: 'Vrouw',
      tel_1: '06-66666666',
      wpl: 'Haarlem',
      bedrijfsnaam: 'NXTMedia',
    },
    {
      adhoc: false,
      kpn: true,
      timestamp: '2023-08-16T23:00:00Z',
      voornaam: 'Thijs',
      achternaam: 'Bakker',
      selectie_id: 12,
      geslacht: 'Man',
      tel_1: '06-44444444',
      wpl: 'Zwolle',
      bedrijfsnaam: 'DigitalSolutions',
    },
    {
      adhoc: true,
      kpn: true,
      timestamp: '2023-08-17T00:00:00Z',
      voornaam: 'Eva',
      achternaam: 'Kramer',
      selectie_id: 13,
      geslacht: 'Vrouw',
      tel_1: '06-11111111',
      wpl: 'Arnhem',
      bedrijfsnaam: 'InnovateMedia',
    },
    {
      adhoc: true,
      kpn: true,
      timestamp: '2023-08-17T01:00:00Z',
      voornaam: 'Max',
      achternaam: 'Van Der Meer',
      selectie_id: 14,
      geslacht: 'Man',
      tel_1: '06-88888888',
      wpl: 'Enschede',
      bedrijfsnaam: 'EcoInnovations',
    },
    {
      adhoc: false,
      kpn: true,
      timestamp: '2023-08-17T02:00:00Z',
      voornaam: 'Julia',
      achternaam: 'Jacobs',
      selectie_id: 15,
      geslacht: 'Vrouw',
      tel_1: '06-77777777',
      wpl: 'Maastricht',
      bedrijfsnaam: 'TechSolutions',
    },
];
  


const StaffCustomersKpn = () => {

    const [filter,setFilter] = useState(0)
    const [searchTerm,setSearchTerm] = useState('')
    const handleSearchChange = (e) => setSearchTerm(e.target.value)
    const handleFilter = (e) => setFilter(e) 



    const PageContent = () => customers.map((customer) => <CustomerPanel 
        adhoc={customer.adhoc}
        kpn={customer.kpn}

        timestamp={customer.timestamp}

        firstName={customer.voornaam}
        lastName={customer.achternaam}
        selectionId={customer.selectie_id}
        gender={customer.geslacht}
        phone={customer.tel_1}
        location={customer.wpl}
        company={customer.bedrijfsnaam}

    />)



    return (
        <FadeIn>
            <Header
                title=""
                date={false}
                stat={true}
                statValue={customerAmount}
                statLabel="customers in database"
                rightElements={<>
                    <InputGroup size='sm' width='200px' marginLeft='10px'>
                        <Input 
                            bg='white'
                            borderRadius='8px'
                            placeholder='Search...' 
                            value={searchTerm}
                            variant='filled'
                            focusBorderColor="blue.500"
                            onChange={handleSearchChange}
                        />
                    
                        <InputLeftElement
                            pointerEvents='none'
                            children={<SearchIcon size="18px" color="black"/>}
                        />
                    </InputGroup>  
                    <Select
                        value={filter}
                        onChange={(e) => handleFilter(e.target.value)}
                        icon={<HiOutlineFilter/>}
                        bg='white'
                        borderRadius='8px'
                        size='sm'
                        variant='filled'
                        width='200px'
                        marginLeft='5px'
                    
                    >

                        <option value='0'>All</option>
                        <option value='1'>Matched</option>
                        <option value='2'>Unmatched</option>
                    </Select>
                </>}
            />
            <Box className='content-container'>
                <SimpleGrid
                    minChildWidth='300px'
                    
                    spacing='10px'
                >
                    <PageContent />
                </SimpleGrid>
            </Box>
        </FadeIn>
    )


}


export default StaffCustomersKpn;