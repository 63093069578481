

import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, SimpleGrid } from "@chakra-ui/react";
import { IconButton, Box, Text, Divider, Flex, useDisclosure } from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";

import './modals.css'
import { HiOutlineFingerPrint, HiOutlineUserCircle } from "react-icons/hi";


const KPN_HEADERS = ['SELECTIE_ID', 'CAMPAGNE_STRAAT', 'CAMPAGNE_CODE', 'CAMPAGNETYPE', 'CAMPAGNECODE_DIVISIE', 'SUB_CAMPAGNECODE_DIVISIE', 'VARIANT', 'PROSPECT_ID', 'AFKAPDATUM_RESPONS', 'CKRNR', 'MCCS', 'GESLACHT', 'TUSSENVOEGSEL', 'ACHTERNAAM', 'VOORLETTERS', 'VOORNAAM', 'PART_ZAK', 'BEDRIJFSNAAM', 'STRAAT', 'HSNR', 'HSNRT', 'PCN', 'PCA', 'WPL', 'TEL_1', 'TEL_2', 'TEL_3', 'EMAILADRES', 'BRONCODE', 'HERKOMSTCODE', 'CONCURRENT_PROVIDER', 'CONCURRENT_ABOTYPE', 'CONCURRENT_EINDCONTRACT', 'CONCURRENT_MAANDBEDRAG', 'CONCURRENT_CONTRACTDUUR', 'CONCURRENT_PRE_POST', 'CONCURRENT_SIM_TST', 'PROPOSITIE', 'EIND_CONTRACT', 'LAST_OUTLET', 'MOSAIC_GROEP', 'MOSAIC_TYPE', 'BELMENIET', 'EXTRA_SELECTIEVELD_1', 'EXTRA_SELECTIEVELD_2', 'EXTRA_SELECTIEVELD_3', 'EXTRA_SELECTIEVELD_4', 'EXTRA_SELECTIEVELD_5', 'EXTRA_SELECTIEVELD_6', 'EXTRA_SELECTIEVELD_7', 'EXTRA_SELECTIEVELD_8', 'EXTRA_SELECTIEVELD_9', 'EXTRA_SELECTIEVELD_10', 'EXTRA_SELECTIEVELD_11', 'EXTRA_SELECTIEVELD_12', 'EXTRA_SELECTIEVELD_13', 'EXTRA_SELECTIEVELD_14', 'EXTRA_SELECTIEVELD_15', 'EXTRA_SELECTIEVELD_16', 'EXTRA_SELECTIEVELD_17', 'EXTRA_SELECTIEVELD_18', 'EXTRA_SELECTIEVELD_19', 'EXTRA_SELECTIEVELD_20']
const ADHOC_HEADERS = ['AHDNR', 'DOSSIERNR', 'SUBDOSSIERNR', 'VESTIGINGSNR', 'HOOFDFIL', 'FANAAM', 'VENNAAM', 'STRAAT', 'HUISNR', 'HUISNRTOEV', 'POSTCODE', 'WOONPLAATS', 'PROVCODE', 'PROVNAAM', 'POSTBUSNR', 'POSTBUSPC', 'POSTBUSWPL', 'TELNET', 'TELABO', 'TELNR', 'MOBIEL', 'EMAIL', 'URL', 'RECHTSVORM', 'SBIHOOFDAC', 'OMSHOOFDACT', 'SBINEVENACT1', 'OMSNEVENACT1', 'SBINEVENACT2', 'OMSNEVENACT2', 'OPRICHTINGSDATUM', 'KLASSEWP', 'KLASSEWPOMS', 'CPFUNCTIE', 'CPSEXE', 'CPTITEL', 'CPVOORL', 'CPTUSSENV', 'CPACHTER', 'KLASSEOMZ', 'KLASSEOMZOMS', 'ECONACTIEF', 'SURSUIT', 'SURSIN', 'FAILUIT', 'FAILVERN', 'INDVERHUIS', 'INDBVG', 'VSI', 'MUTATIEDATUM', 'ACN_X', 'ACN_Y', 'ETRS89_X', 'ETRS89_Y', 'NONMAIL', 'FILIALEN', 'IMPORTEREND', 'EXPORTEREND', 'DOCHTERBEDRIJF', 'MOEDERBEDRIJF', 'FRANCHISEGEVER', 'FRANCHISENEMER', 'POSTORDERGEVOELIG', 'MAILINGACTIEF', 'FAMILIEBEDRIJF', 'GROEIEND', 'STABIEL', 'MVO', 'ANALYTICS', 'TWITTER', 'FACEBOOK', 'LINKEDIN', 'WEBSHOP', 'THUISWINKELWB', 'BOUWJAAR', 'OPPERVLAKTE', 'WINKELFUNCTIE', 'CELFUNCTIE', 'BIJEENKOMSTFUNCTIE', 'INDUSTRIEFUNCTIE', 'KANTOORFUNCTIE', 'LOGIESFUNCTIE', 'ONDERWIJSFUNCTIE', 'OVERIGEFUNCTIE', 'SPORTFUNCTIE', 'GEZONDHEIDSFUNCTIE', 'GEMEENTECODE', 'GEMEENTE', 'CEBUCOCODE', 'CEBUCONAAM', 'COROPCODE', 'COROPNAAM', 'EGGCODE', 'EGGNAAM', 'NIELSENCODE', 'NIELSENOMS']


const KpnContent = (props) => {
    if(!props.kpn) {
        return(
            <Box borderRadius='8px'>
                <Flex alignItems='center'>
                    <HiOutlineUserCircle size='22px' color='#32b4ff' />
                    <Text color='#32b4ff' marginLeft='10px'>KPN DATA</Text>
                </Flex>

                <Divider  marginTop='20px' marginBottom='20px'/>
                <Flex className='modal_details-container' alignItems='center' justifyContent='center'>
                    <Box>
                        <HiOutlineUserCircle size='24px' color='#7f7f7f' style={{margin:'auto auto'}}/>
                        <Text fontSize='xs' color='#7f7f7f'>No KPN Data</Text>
                    </Box>
      
                </Flex>
            </Box>
        )
    }

    return(
        <Box>
            <Flex alignItems='center'>
                <HiOutlineUserCircle size='22px' color='#32b4ff' />
                <Text color='#32b4ff' marginLeft='10px'>KPN DATA</Text>
            </Flex>

            <Divider  marginTop='20px' marginBottom='20px'/>
            <Box className='modal_details-container'>
                {KPN_HEADERS.map((header) => <>
                    <Text fontSize='xs' color='textSecondary.500'>{header.replaceAll("_"," ")}</Text>
                    <Text fontSize='sm' color='textPrimary.500' marginBottom='10px'>{props.kpn[header.toLowerCase()] ? props.kpn[header.toLowerCase()] : "-"}</Text>
                </>)}
            </Box>
        </Box>

    )
}

const AdHocContent = (props) => {
    if(!props.adhoc) {
        return(
            <Box>
                <Flex alignItems='center'>
                    <HiOutlineFingerPrint size='22px' color='#009900' />
                    <Text marginLeft='10px' color='#009900'>ADHOC DATA</Text>
                </Flex>

                <Divider  marginTop='20px' marginBottom='20px'/>
                <Flex className='modal_details-container' alignItems='center' justifyContent='center'>
                    <Box>
                        <HiOutlineFingerPrint size='24px' color='#7f7f7f' style={{margin:'auto auto'}}/>
                        <Text fontSize='xs' color='#7f7f7f'>No ADHOC Data</Text>
                    </Box>
      
                </Flex>
            </Box>
        )
    }
    return(
        <Box>
            <Flex alignItems='center'>
                <HiOutlineFingerPrint size='22px' color='#009900' />
                <Text marginLeft='10px' color='#009900'>ADHOC DATA</Text>
            </Flex>

            <Divider  marginTop='20px' marginBottom='20px'/>
            <Box className='modal_details-container'>
                {ADHOC_HEADERS.map((header) => <>
                    <Text fontSize='xs' color='textSecondary.500'>{header.replaceAll("_"," ")}</Text>
                    <Text fontSize='sm' color='textPrimary.500' marginBottom='10px'>{props.adhoc[header.toLowerCase()] ? props.adhoc[header.toLowerCase()] : "-"}</Text>
                </>)}
            </Box>
        </Box>
    )
}

const KpnDetailModal = (props) => {
    const { isOpen, onOpen, onClose } = useDisclosure()


    return(
        <>
            <IconButton marginRight='6px' size='sm' variant='outline' icon={<SearchIcon />} onClick={() => onOpen()} />

            <Modal size='4xl' isOpen={isOpen} onClose={() => onClose()} isCentered scrollBehavior="inside">
                <ModalOverlay />
                <ModalContent  bg='primary.500'>
                    <ModalHeader>DETAILS</ModalHeader>
                    <ModalCloseButton />

                    <ModalBody>
                        <SimpleGrid minChildWidth='300px' spacing='10px'>
                            <KpnContent kpn={props.kpn} />
                            <AdHocContent adhoc={props.adhoc} />
                        </SimpleGrid>
               
                    </ModalBody>


                </ModalContent>
            </Modal>
        
        </>
    )
} 

export default KpnDetailModal;