

import { Box, Container, Divider, Text } from '@chakra-ui/react'

import './details.css'


const DetailsPanel = (props) => {
    return(
        <Box className='details-panel' width={props.width} height={`${props.height}px`}>
            <Text fontSize='md' color='textPrimary.500'>{props.title}</Text>
            <Divider marginTop='20px' marginBottom='20px' />
            <Box className='details-panel-content' height={`${props.height-110}px`}>
                {props.content}
            </Box>
        </Box>
    )
}

export default DetailsPanel;