import { createSlice } from "@reduxjs/toolkit";
import { api } from "./api";

export const initialState = {
    fileUploadState: {
        success:false,
        error:false,
        loading:false,
        message:''
    },

    fileDeleteState: {
        success:false,
        error:false,
        loading:false,
        message:''
    },

    toastType:null,
    toastValue:"Success!",
}

const staffFileSlice = createSlice({
    name:'staffFile',
    initialState,
    reducers: {
        setFileUploadState: (state, action) => {state.fileUploadState = action.payload},
        setFileDeleteState: (state, action) => {state.fileDeleteState = action.payload},

        setToastValue: (state, action) => {state.toastValue = action.payload},
        setToastType: (state, action) => {state.toastType = action.payload}
    }
})

export const {

    setFileDeleteState, setFileUploadState, setToastType, setToastValue

} = staffFileSlice.actions

export default staffFileSlice.reducer;



