import { extendTheme } from "@chakra-ui/react";


const theme = extendTheme({
  config: {
    initialColorMode: "light",
    useSystemColorMode: false,
  },
  colors: {
    primary: {
      500:'#FFFFFF'
    },
    secondary: {
      500:'#EEEEEE'
    },
    green:{
        500:'#009900'
    },
    blue: {
        500:'#32b4ff'
    },
    textPrimary: {
        500:'#000000'
    },
    textSecondary: {
        500:'#7f7f7f'
    }


  },
  styles: {
    global: () => ({
      body: {
        bg: "#EEEEEE",
      }
      
    })
  }
})
export default theme;