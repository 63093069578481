
import React from "react";
import StaffPageWrapper from "./PageWrapper";
import FadeIn from 'react-fade-in'
import Header from '../../components/header/Header'
import Datagrid from "../../components/table/Datagrid";

import { Tr, Td, Tag, Progress } from "@chakra-ui/react";
import { Text, Tooltip } from "@chakra-ui/react";

import { HiOutlineDotsCircleHorizontal, HiOutlineCheckCircle, HiOutlineClock, HiOutlineCode, HiOutlineChatAlt, HiOutlineExclamationCircle } from "react-icons/hi";


const TABLE_HEAD = [
    {
        label:'',
        width:'20px'
    },
    {
        label:'Id',
        width:'20px'
    },
    {
        label:'Type',
        width:'20px'
    },
    {
        label:'Progress'
    },
    {
        label:'Status'
    },
    {
        label:'Runtime',
        width:'20px'
    },
    {
        label:'Timestamp',
        width:'20px'
    },
    {
        label:'Created by',
        width:'20px'
    },
    {
        label:'',
        width:'20px'
    }
]




const taskAmount = 10
const tasks = [
    {
        is_complete: true,
        is_queued: false,
        is_error: false,
        id: 1,
        task_type: 'C',
        progress: 100,
        total: 100,
        runtime: 120,
        timestamp: '2023-08-16T12:00:00Z',
        created_by: 'Alice',
        message: 'Import completed successfully.',
        status: 'COMPLETED',
      },
      {
        is_complete: false,
        is_queued: true,
        is_error: false,
        id: 2,
        task_type: 'D',
        progress: 0,
        total: 200,
        runtime: 60,
        timestamp: '2023-08-16T13:00:00Z',
        created_by: 'Bob',
        message: '',
        status: 'QUEUED',
      },
      {
        is_complete: true,
        is_queued: false,
        is_error: false,
        id: 3,
        task_type: 'M',
        progress: 75,
        total: 100,
        runtime: 90,
        timestamp: '2023-08-16T14:00:00Z',
        created_by: 'Charlie',
        message: 'Matching completed.',
        status: 'COMPLETED',
      },
];
  
  



const StaffTasks = () => {

    const QueuedIcon = (props) => {
        return <Tooltip hasArrow label='Task queued.' fontSize='sm' bg='secondary.500' color='textMain.500'>
            <Tag size='xl' backgroundColor='transparent'>
                <HiOutlineDotsCircleHorizontal size='20px' color='#7f7f7f'/>
            </Tag>
        </Tooltip>
    }
    const CompleteIcon = (props) => {
        return <Tooltip hasArrow label='Task complete!' fontSize='sm' bg='secondary.500' color='textMain.500'>
            <Tag size='xl' backgroundColor='transparent'>
                <HiOutlineCheckCircle size='20px' color='#009900'/>
            </Tag>
        </Tooltip>
    }
    const InProgressIcon = (props) => {
        return <Tooltip hasArrow label='Task in progress.' fontSize='sm' bg='secondary.500' color='textMain.500'>
            <Tag size='xl' backgroundColor='transparent'>
                <HiOutlineClock size='20px' color='#32b4ff'/>
            </Tag>
        </Tooltip>
    }
    const ErrorIcon = (props) => {
        return <Tooltip hasArrow label='Error!' fontSize='sm' bg='secondary.500' color='textMain.500'>
            <Tag size='xl' backgroundColor='transparent'>
                <HiOutlineExclamationCircle size='20px' color='#E53E3E'/>
            </Tag>
        </Tooltip>
    }
    const CodeIcon = (props) => {
        const handleCopy = () => {
            navigator.clipboard.writeText(props.id)
        }


        return <Tooltip hasArrow label={props.id} fontSize='sm' bg='secondary.500' color='textMain.500'>
            <Tag size='xl' backgroundColor='transparent' onClick={() => handleCopy()}>
                <HiOutlineCode size='20px' color='#000000'/>
            </Tag>
        </Tooltip>
    }
    const MessageIcon = (props) => {
        return <Tooltip hasArrow label={props.message} fontSize='sm' bg='secondary.500' color='textMain.500'>
            <Tag size='xl' backgroundColor='transparent'>
                <HiOutlineChatAlt size='20px' color='#000000'/>
            </Tag>
        </Tooltip>
    }



    const getRunTime = (runtime) => {
        if(runtime >= 60) return parseInt(runtime / 60).toString() + ' min'
        else return runtime.toString() + ' sec'
        
    }
    const getStatusIcon = (isComplete,isQueued,isError) => {
        if(isComplete) return <CompleteIcon />
        else if(isQueued) return <QueuedIcon />
        else if(isError) return <ErrorIcon />
        else return <InProgressIcon />
    }
    const getTaskType = (type) => {
        switch(type) {
            case 'C': return 'IMPORT'
            case 'D': return 'DELETE'
            case 'M': return 'MATCH'
            case 'U': return 'UNMATCH'
            default: return ''
        }
    }
    const getTaskStatus = (task) => {
        if(task.is_queued) return 'Queued...'
        else if(task.is_complete) return `${task.status} - ${task.total}`
        else return(`${task.status} [${task.progress}/${task.total}]`)
    }

    const TableContent = () => tasks.map((task) => <Tr>
        <Td>
            {getStatusIcon(task.is_complete,task.is_queued,task.is_error)}
            
        </Td>
        <Td><CodeIcon id={task.id} /></Td>
        <Td><Tag><Text fontSize='xs' noOfLines={1}>{getTaskType(task.task_type)}</Text></Tag></Td>
        <Td>
            <Progress colorScheme={task.is_complete ? 'green' : 'blue'} borderRadius='8px' value={task.is_queued ? 0 : (task.progress / task.total) * 100} />
        </Td>
        <Td><Text fontSize='xs' noOfLines={1}>{getTaskStatus(task)}</Text></Td>
        <Td><Text fontSize='xs' noOfLines={1}>{getRunTime(task.runtime)}</Text></Td>
        <Td><Text fontSize='xs' noOfLines={1}>{task.timestamp.substring(0,19)}</Text></Td>
        <Td><Text fontSize='xs' noOfLines={1}>{task.created_by}</Text></Td>
        <Td>{task.message ? <MessageIcon message={task.message} /> : <></>}</Td>
    </Tr>)


    return(
        <StaffPageWrapper page={
            <div style={{overflow:'hidden', paddingBottom:'20px'}}>
                <FadeIn>
                    <Header
                        title="Tasks"
                        date={false}
                        stat={true}
                        statValue={taskAmount}
                        statLabel="tasks in database"
             
                    />
                    <Datagrid 
                        tableHeader={TABLE_HEAD}
                        tableContent={<TableContent />}

                        loading={false}
                        error={false}

                        results={tasks}
                        page={1}
                        maxPage={1}

                    />
                </FadeIn>
            </div>
        }
        />
    )
}


export default StaffTasks;