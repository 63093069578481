import { createSlice } from "@reduxjs/toolkit";
import { api } from "./api";

export const initialState = {
    loading: true,
    error:false,

    kpnFiles:[],
    nettoFiles:[],

    log:[],
    allLogs:[],

    kpnFilesAmount:0,
    nettoFilesAmount:0,

    customers:0,
    sales:0,

    toastType:null,
    toastValue:"Success!",
}

const staffMasterSlice = createSlice({
    name:'staffMaster',
    initialState,
    reducers: {

        setLoading: (state, action) => {state.loading = action.payload},
        setError: (state, action) => {state.error = action.payload},

        setLog: (state, action) => {state.log = action.payload},

        setKpnFiles: (state, action) => {state.kpnFiles = action.payload},
        setNettoFiles: (state, action) => {state.nettoFiles = action.payload},

        setKpnFilesAmount: (state, action) => {state.kpnFilesAmount = action.payload},
        setNettoFilesAmount: (state, action) => {state.nettoFilesAmount = action.payload},

        setCustomers: (state, action) => {state.customers = action.payload},
        setSales: (state, action) => {state.sales = action.payload},


        setToastValue: (state, action) => {state.toastValue = action.payload},
        setToastType: (state, action) => {state.toastType = action.payload}
    }
})

export const {

    setLoading, setError, setToastType, setToastValue, setFiles, setLog, 
    setKpnFiles, setNettoFiles, setKpnFilesAmount, setNettoFilesAmount, setCustomers, setSales

} = staffMasterSlice.actions

export default staffMasterSlice.reducer;




export function getMasterbestand() {
    return async (dispatch) => {
        dispatch(setLoading(true))
        dispatch(setError(false))




        api
            .get("master/panel")
            .then((res) => {
                console.log(res.data.files)

                dispatch(setLoading(false))
                dispatch(setError(false))

                dispatch(setKpnFiles(res.data.files))                
                dispatch(setLog(res.data.log))                
                dispatch(setKpnFilesAmount(res.data.kpn_files_amount))                
                dispatch(setNettoFilesAmount(res.data.netto_files_amount))                
                dispatch(setCustomers(res.data.customer_amount))                
                dispatch(setSales(res.data.sale_amount))                
            })
            .catch((err) => {
                dispatch(setLoading(false))
                dispatch(setError(true))
            })

    }
}

export function getAllLogs() {
    return async (dispatch) => {
        dispatch(setLoading(true))
        dispatch(setError(false))




        api
            .get("master/log")
            .then((res) => {
                dispatch(setLoading(false))
                dispatch(setError(false))

                dispatch(setLog(res.data.log))                
            })
            .catch((err) => {
                dispatch(setLoading(false))
                dispatch(setError(true))
            })

    }
}