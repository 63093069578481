
import React, { useState, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";

import FadeIn from 'react-fade-in'

import { api } from "../../slices/staff/api";

import { Button, useToast, Alert, AlertIcon, Progress, Text, Flex, Box, Switch } from "@chakra-ui/react";
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter } from "@chakra-ui/react";
import { useDisclosure } from "@chakra-ui/react";


import { setFileUploadState } from "../../slices/staff/files";
import { getMasterbestand, setToastType, setToastValue } from "../../slices/staff/master";

import { useDispatch, useSelector } from "react-redux";
import { DownloadIcon } from "@chakra-ui/icons";




import FileUpload from "../../components/fileUpload/FileUpload";

const CHUNK_SIZE = 1048576 * 1;      //its 3MB, increase the number measure in mb


const sleep = (milliseconds) => {
    return new Promise(resolve => setTimeout(resolve, milliseconds))
}

const AdHocImportModal = () => {
    const toast = useToast()
    const dispatch = useDispatch()

    const { isOpen, onOpen, onClose } = useDisclosure()
    const { fileUploadState, toastType, toastValue } = useSelector(state => state.staffFile)

    const [matchAfter,setMatchAfter] = useState(true)

    const [adHocFile,setAdHocFile] = useState(null)                 // for file input
    const [fileToBeUpload, setFileToBeUpload] = useState({});       // for upload
    const [fileId, setFileId] = useState("")                        // for django to match file
    const [taskId, setTaskId] = useState('')
    const [fileSize, setFileSize] = useState(0);
    const [fileName, setFileName] = useState('');

    const [counter, setCounter] = useState(1);
    const [beginingOfTheChunk, setBeginingOfTheChunk] = useState(0);
    const [endOfTheChunk, setEndOfTheChunk] = useState(CHUNK_SIZE);
    const [chunkCount, setChunkCount] = useState(0);

    const [showProgress, setShowProgress] = useState(false)
    const [progress, setProgress] = useState(0);

    const [creatingCustomers,setCreatingCustomers] = useState(false)
    const [creatingCustomersState,setCreatingCustomersState] = useState('Queued...')

    const [customerProgress,setCustomerProgress] = useState(0)
    const [customerTotal,setCustomerTotal] = useState(1)
    const [isQueued,setIsQueued] = useState(true)


    useEffect(() => {
        if (fileSize > 0) {
            setShowProgress(true);
            fileUpload(counter);
        }

        if (creatingCustomers) {
            setShowProgress(true);
            checkCustomerCreationProgress();
        }
    }, [fileToBeUpload, progress]);

    const fileUpload = () => {
        setCounter(counter + 1);
        if (counter <= chunkCount) {
            var chunk = fileToBeUpload.slice(beginingOfTheChunk, endOfTheChunk);
            uploadChunk(chunk);
        }
    };
    const getFileContext = (e) => {
        const _file = e;
        setFileSize(_file.size);
        const _totalCount =
            _file.size % CHUNK_SIZE == 0
                ? _file.size / CHUNK_SIZE
                : Math.floor(_file.size / CHUNK_SIZE) + 1; // ONCE ALL CHUNKS ARE UPLOADED
            setChunkCount(_totalCount);

            const [prefix,suffix] = _file.name.split('.')
            const name = `${prefix.replaceAll(" ","_")}-${uuidv4().split('-').pop()}.${suffix}`

            setFileName(name)
            setFileToBeUpload(_file);
    };
    const uploadChunk = async (chunk) => {
        var bodyFormData = new FormData()
        

        bodyFormData.append('id',fileId)
        bodyFormData.append('name',fileName)
        bodyFormData.append('chunk',chunk)

        await api
            .post("kpn/file/adhoc/", bodyFormData )
            .then((res) => {

                if(res.data.id) {
                    setFileId(res.data.id)
                }

                // IF UPLOAD IS SUCCESSFUL
                setBeginingOfTheChunk(endOfTheChunk);
                setEndOfTheChunk(endOfTheChunk + CHUNK_SIZE);
                if (counter == chunkCount) {
                    console.log("Process is complete, counter", counter);
                    setProgress(100)
                    setShowProgress(false)
                    uploadCompleted(res.data.id);
                } else {
                var percentage = (counter / chunkCount) * 100;
                console.log(percentage)
                setProgress(percentage);
                }
            })
            .catch((err) => {
                dispatch(setFileUploadState({
                    success:false,
                    error:true,
                    loading:false,
                    message:'Something went wrong...'
                }))
            })

        
    };
    const uploadCompleted = async (id) => {

        let fileIdentification
        if(fileId==="") fileIdentification = id
        else fileIdentification = fileId


        api
            .post("kpn/customer/adhoc/", {
                id:fileIdentification,
                match:matchAfter
            })
            .then((res) => {
                if(res.data.success) {
                    dispatch(setFileUploadState({
                        success:false,
                        error:false,
                        loading:false,
                        message:''
                    }))
                    setProgress(0)
                    setCreatingCustomers(true)
                    setTaskId(res.data.task_id)
                }
                else {
                    dispatch(setToastType('error'))
                    dispatch(setToastValue(res.data.message))
                }
            })
            .catch((err) => {
                dispatch(setToastType('error'))
                dispatch(setToastValue('Something went wrong...'))
            })



    };
    const checkCustomerCreationProgress = async () => {
        await sleep(3000)

        await api
            .get(`kpn/customer/task?id=${taskId}&type=C`)
            .then((res) => {
                if(res.data.complete) {
                    setCustomerProgress(res.data.progress)
                    setCustomerTotal(res.data.total)
                    setCreatingCustomersState(res.data.status)

                    dispatch(setFileUploadState({
                        success:true,
                        error:false,
                        loading:false,
                        message:res.data.message
                    }))
                    setShowProgress(false)
                }
                else if(res.data.error) {
                    dispatch(setFileUploadState({
                        success:false,
                        error:true,
                        loading:false,
                        message:res.data.message
                    }))
                    setShowProgress(false)
                }
                else if(res.data.queued) {
                    setProgress(progress+0.0000001)
                    setIsQueued(true)
                }
                else {
                    setCustomerProgress(res.data.progress)
                    setCustomerTotal(res.data.total)
                    setCreatingCustomersState(res.data.status)
                    setIsQueued(res.data.queued)
                    var percentage = (res.data.progress / res.data.total) * 100;
                    if(percentage === progress) setProgress(percentage+0.0000001)
                    else setProgress(percentage)
                }
            })
            .catch((err) => {
                setProgress(progress+0.0000001)
                dispatch(setToastType('info'))
                dispatch(setToastValue('Error checking task state...'))
            })



    };



    const handleOpen = () => {
        onOpen();
    }
    const handleImport = () => {
        if(!adHocFile) {
            toast({
                title: 'Please upload a file',
                status: 'info',
                duration: 4000,
                isClosable: true,
            });
            return;
        }

        dispatch(setFileUploadState({
            success:false,
            error:false,
            loading:true,
            message:''
        }))
        getFileContext(adHocFile)
    }
    const handleClose = () => {
        dispatch(getMasterbestand())
        dispatch(setFileUploadState({
            success:false,
            error:false,
            loading:false,
            message:''
        }))
        onClose();
    }





    const handleAdhocFile = (e) => setAdHocFile(e.target.files[0])
    const handleResetAdhocFile = () => setAdHocFile(null)






    return(
        <>
            <Button
                bg='secondary.500'
                borderRadius='8px'
                focusBorderColor="blue.500"
                size='sm'
                marginRight='5px'
                leftIcon={<DownloadIcon />}
                onClick={handleOpen}
            >
                Import AdHoc file
            </Button>

            <Modal isOpen={isOpen} onClose={handleClose} isCentered scrollBehavior="inside">
                <ModalOverlay />
                <ModalContent  bg='primary.500' width='400px'>
                    <ModalHeader>Import AdHoc File</ModalHeader>
                    <ModalCloseButton />

                    <ModalBody>
                        {
                            fileUploadState.error ? 
                            <FadeIn>
                                <Alert fontSize='xs' status='error' borderRadius='6px' marginBottom='20px'>
                                    <AlertIcon />
                                    {fileUploadState.message}
                                </Alert>
                            </FadeIn>
                            
                            : 
                            <></>
                        }

                        {
                            fileUploadState.success ? 
                            <FadeIn>
                                <Alert fontSize='xs' status='success' borderRadius='6px' marginBottom='20px'>
                                    <AlertIcon />
                                    {fileUploadState.message}
                                </Alert>
                            </FadeIn>
                            
                            : 
                            <></>
                        }

                        {!showProgress ? 
                            <FileUpload 
                                defaultText='Add AdHoc file'
                                file={adHocFile}
                                accept='.csv'
                                onFileChange={(file) => handleAdhocFile(file)}
                                onFileReset={() => handleResetAdhocFile()}
                            /> : <></>
                    
                        }
                        

                        {showProgress ? 
                            <Box marginTop='20px'>
                                <Text fontSize='xs' color='textSecondary.500'>{creatingCustomers ? creatingCustomersState : 'Uploading file...'} {!creatingCustomers || !isQueued ? `${progress.toFixed(0)}%` : ""}     {creatingCustomers && !isQueued ? `[${customerProgress} / ${customerTotal}]` : ""}</Text>
                                <Progress colorScheme='blue' size='sm' value={progress} borderRadius='8px' /> 
                            </Box>:
                            <></>
                        
                        }

                        {!showProgress ? 
                        
                            <Flex marginTop='20px' alignItems='center' justifyContent='left'>
                                <Switch colorScheme='blue' size='sm' isChecked={matchAfter} onChange={() => setMatchAfter(!matchAfter)} />
                                <Text color='textSecondary.500' marginLeft='10px' fontSize='xs'>Match contacts after import</Text>
                            </Flex> : <></>
                    
                        }





                    </ModalBody>

                    <ModalFooter>
                        <Button isLoading={fileUploadState.loading} leftIcon={<DownloadIcon />} variant='solid' marginLeft='10px' onClick={() => handleImport()}>
                            Import
                        </Button>
                    </ModalFooter>

                </ModalContent>
            </Modal>
            
        </>
        
    )
}



export default AdHocImportModal;