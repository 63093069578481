
import React, { useState } from "react";
import StaffPageWrapper from "./PageWrapper";
import FadeIn from 'react-fade-in'
import Header from '../../components/header/Header'
import Datagrid from "../../components/table/Datagrid";

import { Tag, Td, Tr, Text, IconButton, Flex } from "@chakra-ui/react";

import AdHocDeleteModal from '../../components/modals/AdHocDeleteModal'
import KpnDeleteModal from '../../components/modals/KpnDeleteModal'


import { HiOutlineDocumentText } from "react-icons/hi";
import { DownloadIcon } from "@chakra-ui/icons";

const PAGE_SIZE = 25;
const TABLE_HEAD = [
    {
        label:'',
        width:'20px'
    },
    {
        label:'Name'
    },
    {
        label:'New Customers'
    },
    {
        label:'Type'
    },
    {
        label:'Uploader'
    },
    {
        label:'Timestamp'
    },
    {
        label:''
    },

]
const TABLE_FILTERS = [
    {
        label:'All',
        value:'0'
    },
    {
        label:'KPN',
        value:'1'
    },
    {
        label:'D2D',
        value:'2'
    },
    {
        label:'AdHoc',
        value:'3'
    },
]


const FILE_TYPES = {
    'K':'KPN',
    'A':'ADHOC',
    'D':'D2D',
}


const page = 1
const fileAmount = 5

const files = [
    {
      name: 'Sales_Q1.pdf',
      customer_count: 100,
      file_variant: 'K',
      user: 'Alice',
      timestamp: '2023-08-16T12:00:00Z',
      file: 'sales_q1.pdf'
    },
    {
      name: 'Marketing_Campaign.docx',
      customer_count: 50,
      file_variant: 'A',
      user: 'Bob',
      timestamp: '2023-08-16T13:00:00Z',
      file: 'marketing_campaign.docx'
    },
    {
      name: 'Financial_Reports.xlsx',
      customer_count: 200,
      file_variant: 'D',
      user: 'Charlie',
      timestamp: '2023-08-16T14:00:00Z',
      file: 'financial_reports.xlsx'
    },
    {
        name: 'Product_Info.pdf',
        customer_count: 150,
        file_variant: 'A',
        user: 'Eva',
        timestamp: '2023-08-16T17:00:00Z',
        file: 'product_info.pdf'
      },
      {
        name: 'Customer_Survey.xlsx',
        customer_count: 80,
        file_variant: 'D',
        user: 'Frank',
        timestamp: '2023-08-16T18:00:00Z',
        file: 'customer_survey.xlsx'
      },
      {
        name: 'Promotional_Materials.pptx',
        customer_count: 120,
        file_variant: 'K',
        user: 'Grace',
        timestamp: '2023-08-16T19:00:00Z',
        file: 'promotional_materials.pptx'
      },
  ];



const getCleanDate = (date) => {
    let [dateStr,timeStr] = date.split('T')

    let [year,month,day] = dateStr.split('-')



    return `${day}/${month}/${year} ${timeStr.substring(0,5)}`
}

const StaffCustomerFiles = () => {

    const [search,setSearch] = useState('')
    const [filter,setFilter] = useState(0)


    const DeleteButton = (file) => {
        if(file.file.file_variant === 'K' || file.file.file_variant === 'D') return <KpnDeleteModal type={file.file.file_variant} id={file.file.id} pageSettings={[page,PAGE_SIZE,search,filter]} size='xs' />
        else if(file.file.file_variant === 'A') return <AdHocDeleteModal id={file.file.id} pageSettings={[page,PAGE_SIZE,search,filter]} size='xs' />
    }




    const TableContent = () => files.map((file) => <Tr>
        <Td><HiOutlineDocumentText color='black' size='20px' /></Td> 
        <Td><Text fontSize='xs' noOfLines={1}>{file.name}</Text></Td>
        <Td><Text fontSize='xs' noOfLines={1}>{file.customer_count}</Text></Td>
        <Td><Tag><Text fontSize='xs' noOfLines={1}>{FILE_TYPES[file.file_variant]}</Text></Tag></Td>

        <Td><Tag><Text fontSize='xs' noOfLines={1}>{file.user}</Text></Tag></Td>
        <Td><Text fontSize='xs' noOfLines={1}>{getCleanDate(file.timestamp)}</Text></Td>
        <Td>
            <Flex alignItems='center' justifyContent='right'>
                <IconButton marginRight='6px' size='xs' variant='outline' icon={<DownloadIcon />} />
                <DeleteButton file={file} pageSettings={[page,PAGE_SIZE,search,filter]} />
                
            </Flex>        
        </Td>
    </Tr>)




    return(
        <StaffPageWrapper page={
            <div style={{overflow:'hidden', paddingBottom:'20px'}}>
                <FadeIn>
                    <Header
                        title="Customer Files"
                        date={false}
                        stat={true}
                        statValue={fileAmount}
                        statLabel="files in database"
                    />
                    <Datagrid 
                        tableHeader={TABLE_HEAD}
                        tableFilter={TABLE_FILTERS}
                        tableContent={<TableContent />}

                        loading={false}
                        error={false}

                        results={files}
                        page={page}
                        maxPage={1}

                    />

                    
                </FadeIn>
            </div>
        }
        />
    )
}


export default StaffCustomerFiles;