
import React from "react";
import StaffPageWrapper from "./PageWrapper";
import FadeIn from 'react-fade-in'
import Header from '../../components/header/Header'
import Datagrid from "../../components/table/Datagrid";

import { Tag, Td, Tr, Text, useToast, IconButton } from "@chakra-ui/react";
import { useSelector, useDispatch } from "react-redux";
import { setSearch, previousPage, nextPage, getNettoFiles, setToastType, setFilter } from '../../slices/staff/netto'

import { Link } from "react-router-dom";


import { HiOutlineDocumentText } from "react-icons/hi";
import { DownloadIcon } from "@chakra-ui/icons";

const PAGE_SIZE = 25;
const TABLE_HEAD = [
    {
        label:'',
        width:'20px'
    },
    {
        label:'Name'
    },
    {
        label:'Masterbestand'
    },
    {
        label:'Type'
    },
    {
        label:'Timestamp'
    },
    {
        label:'',
    }
]
const TABLE_FILTERS = [
    {
        label:'All',
        value:'0'
    },
    {
        label:'KZ',
        value:'1'
    },
    {
        label:'KZ Cancelled',
        value:'2'
    },
    {
        label:'KZ Mob',
        value:'2'
    },
]

const data = [
    {
        id:1,
        name:'doc-22-08-2022.csv',
        master:'masterbestand',
        type:'KZ',
        timestamp:'22/08/2022'
    },
    {
        id:2,
        name:'doc-22-08-2022.csv',
        master:'masterbestand',
        type:'KZ cancellation',
        timestamp:'22/08/2022'
    },
    {
        id:3,
        name:'doc-22-08-2022.csv',
        master:'masterbestand',
        type:'KZ mob',
        timestamp:'22/08/2022'
    },
    {
        id:4,
        name:'doc-22-08-2022.csv',
        master:'masterbestand',
        type:'KZ',
        timestamp:'22/08/2022'
    },
    {
        id:5,
        name:'doc-22-08-2022.csv',
        master:'masterbestand',
        type:'KZ',
        timestamp:'22/08/2022'
    },
]


const StaffSalesFiles = () => {
    const toast = useToast()
    const dispatch = useDispatch()

    const { loading, error, maxPage, search, page, files, fileAmount, filter } = useSelector(state => state.staffNetto)
    const { toastType, toastValue } = useSelector(state => state.staffNetto)



    const handleNextPage = () => dispatch(nextPage());
    const handlePreviousPage = () => dispatch(previousPage());
    const handleFilter = (f) => dispatch(setFilter(f));

    const handleSearchConfirm = (value) => dispatch(setSearch(value));
    const handleDeleteFile = () => {

    }


    const TableContent = () => {
        return(
            <>
                {data.map((data) => {
                    return(
                        <Tr>
                            <Td><HiOutlineDocumentText color='black' size='20px' /></Td> 
                            <Td><Text fontSize='xs' noOfLines={1}>{data.name}</Text></Td>
                            <Td>
                                <Link to='/staff/master/1'>
                                    <Tag cursor='pointer'>
                                        <Text fontSize='xs' noOfLines={1}>{data.master}</Text>
                                    </Tag>
                                </Link>
                            </Td>
                            <Td>
                                <Tag >
                                    <Text fontSize='xs' noOfLines={1}>{data.type}</Text>
                                </Tag>
                            </Td>
                            <Td><Text fontSize='xs' noOfLines={1}>{data.timestamp}</Text></Td>
                            <Td>
                                <IconButton size='xs' variant='outline' icon={<DownloadIcon color='green.500' />} />
                            </Td>
                        </Tr>
                    )
                })}
            </>
        )
    }





    React.useEffect(() => {
        if(toastType) toast({
            title:toastValue,
            status: toastType,
            duration: 4000,
            isClosable: true,
        })
        dispatch(setToastType(null))
    })

    React.useEffect(() => {
        dispatch(getNettoFiles(page,PAGE_SIZE,search,filter))
    }, [page,search,filter])


    return(
        <StaffPageWrapper page={
            <div style={{overflow:'hidden', paddingBottom:'20px'}}>
                <FadeIn>
                    <Header
                        title="Netto Files"
                        date={false}
                        stat={true}
                        statValue={fileAmount}
                        statLabel="files in database"
                    />
                    <Datagrid 
                        tableHeader={TABLE_HEAD}
                        tableFilter={TABLE_FILTERS}
                        tableContent={<TableContent />}

                        loading={loading}
                        error={error}

                        results={files}
                        page={page}
                        maxPage={maxPage}

                        nextPage={() => handleNextPage()}
                        prevPage={() => handlePreviousPage()}
                        confirmSearch={(s) => handleSearchConfirm(s)}
                        setFilter={((f) => handleFilter(f))}
                    />
                </FadeIn>
            </div>
        }
        />
    )
}


export default StaffSalesFiles;