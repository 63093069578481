

import useWindowSize from "../../tools/useWindow"
import { Box, Flex } from "@chakra-ui/react"
import ResponsiveNavbar from "../../components/nav/StaffNavbar"

import './page.css'

const StaffPageWrapper = (props) => {

    const [windowWidth,windowHeight] = useWindowSize()


    if(windowWidth < 720) {
        return(
            <Box>
                <ResponsiveNavbar />
                <Box className='mobile-page-container' overflowY='auto'>
                    {props.page}
                </Box>
            </Box>
        )
    }
    else {
        return(
            <Flex style={{height:"100vh",overflow:'hidden'}}>
                <ResponsiveNavbar />
                <Box className='page-container' overflowY='auto'>
                    {props.page}
                </Box>
            </Flex>
        )
        
    }
}

export default StaffPageWrapper;