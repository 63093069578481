import { createSlice } from "@reduxjs/toolkit";
import { api, resetAPI } from "./api";

const STAFF_KEY = '7a7OBcR0KBT3yCsA8DO2L65qrKpcjOPFNTcqV64Q'

export const initialState = {
    loading:false,
    error:false,
    
    authenticated:false,
    authLoading:true,
    errorText:'Something went wrong...',
    
    user: {
        id:"",
        email:"",
        name:"",
        organization:"",
        phone_number:""
    }
}



const staffAuthSlice = createSlice({
    name:'staffAuth',
    initialState,
    reducers: {
        setLoading: (state, action) => {
            state.loading = action.payload;
        },
        setAuthenticated: (state, action) => {
            state.authLoading = false;
            state.loading = false;
            state.error = false;
            state.authenticated = action.payload;
        },
        setUser: (state,action) => {
            state.user = action.payload
        },
        setError: (state, action) => {
            state.loading = false;
            state.error = true;
            state.errorText = action.payload;
        },
    }
})

export const{ setLoading, setAuthenticated, setUser, setError } = staffAuthSlice.actions;
export default staffAuthSlice.reducer;




export function checkAuth() {
    return async (dispatch) => {
        dispatch(setLoading(true))
        api
            .get("auth/staff/auth")
            .then((res) => {
                dispatch(setAuthenticated(true));
                dispatch(setUser(res.data.user));
                dispatch(setLoading(false))

            })
            .catch(() => {
                dispatch(setAuthenticated(false));
                dispatch(setLoading(false));
                localStorage.clear()
                resetAPI()
            })
    };
}

export function loginUser(email,password) {
    return async (dispatch) => {
        dispatch(setLoading(true))
        api
            .post("auth/staff/signin/", {
                "client_id":STAFF_KEY,
                "email":email,
                "password":password,
            })
            .then((res) => {
                if(!res.data.success) dispatch(setError(res.data.message))
                else {
                    localStorage.setItem('access_token',res.data.access_token)
                    localStorage.setItem('refresh_token',res.data.refresh_token)

                    dispatch(setAuthenticated(true))
                    dispatch(setUser(res.data.user))
                    resetAPI()
                }
            })
            .catch((err) => {
                if (err.code === 'ECONNABORTED') dispatch(setError('Connection timed out...'))
                else {
                    dispatch(setError('Something went wrong...'))
                    localStorage.clear()
                    resetAPI()
                }
            });
    };
}


export function logoutUser() {
    return async (dispatch) => {
        dispatch(setLoading(true))
        api
            .get("auth/staff/signout/")
            .then(() => {
                localStorage.setItem('access_token',null)
                localStorage.setItem('access_token',null)

                dispatch(setAuthenticated(false))
            })
            .catch(() => {
                localStorage.setItem('access_token',null)
                localStorage.setItem('access_token',null)

                dispatch(setAuthenticated(false))            
            });
    };
}
