
import { Box, Flex, IconButton, Text, Menu, MenuButton, MenuList, MenuItem, Divider, Tag, Tooltip } from "@chakra-ui/react";

import KpnDetailModal from "../modals/KpnDetailModal";
import './panels.css'

import { HiOutlineFingerPrint, HiOutlineLocationMarker, HiOutlinePhone, HiOutlineOfficeBuilding, HiOutlinePuzzle } from "react-icons/hi";
import { SearchIcon } from "@chakra-ui/icons";

const CustomerPanel = (props) => {

    return(
        <Box className='customer-panel'>
            <Flex justifyContent='space-between' alignItems='top' marginBottom='20px'>
                <Flex alignItems='center' justifyContent='left'>
                    <Box className='icon-container'>
                        <HiOutlineFingerPrint size='28px' color='#009900' />
                    </Box>      

                    <Box>
                        <Text marginLeft='10px' maxW='200px' fontSize='xs' noOfLines={1}>{props.company}</Text>
                        <Text marginLeft='10px' fontSize='xs' noOfLines={1} color='textSecondary.500'>{props.ahdnr}</Text>

                    </Box>

                </Flex>
                <KpnDetailModal kpn={props.kpn} adhoc={props.adhoc} />

            </Flex>
            <Divider marginBottom='14px' />
            <Flex alignItems='center' marginBottom='10px'>
                <HiOutlinePuzzle size='20px' color={props.kpn ? '#48BB78' : '#F56565'} />
                <Text fontSize='xs' marginLeft='5px' color={props.kpn ? '#48BB78' : '#F56565'}>{props.kpn ? 'KPN profile linked!' : 'No KPN / D2D profile linked!'}</Text>
            </Flex>
            <Flex alignItems='center' marginBottom='10px'>
                <HiOutlineOfficeBuilding size='20px' color='#7f7f7f' />
                <Text fontSize='xs' marginLeft='5px' color='textSecondary.500'>{props.company}</Text>
            </Flex>
            <Flex alignItems='center' marginBottom='10px'>
                <HiOutlineLocationMarker size='20px' color='#7f7f7f' />
                <Text fontSize='xs' marginLeft='5px' color='textSecondary.500'>{props.location}</Text>
            </Flex>
            <Flex alignItems='center' marginBottom='10px'>
                <HiOutlinePhone size='20px' color='#7f7f7f' />
                <Text fontSize='xs' marginLeft='5px' color='textSecondary.500'>{props.phone}</Text>
            </Flex>
        </Box>
    )
}

export default CustomerPanel;

