
import React from "react";
import FadeIn from 'react-fade-in';
import Datagrid from '../../components/table/Datagrid'

import { useSelector, useDispatch } from "react-redux";
import { setSearch, previousPage, nextPage, getData, setToastType, setFilter } from '../../slices/staff/apiSalesDock'
import { useToast } from "@chakra-ui/react";





const PAGE_SIZE = 25;
const TABLE_HEAD = [
    {
        label:'',
        width:'20px'
    },
    {
        label:'id'
    },
    {
        label:'customer'
    },
    {
        label:'product'
    },
    {
        label:'price'
    },
    {
        label:'',
    }
]


const StaffSalesDock = () => {
    const toast = useToast()
    const dispatch = useDispatch()

    const { loading, error, maxPage, search, page, data, filter } = useSelector(state => state.staffSalesDock)
    const { toastType, toastValue } = useSelector(state => state.staffSalesDock)



    const handleNextPage = () => dispatch(nextPage());
    const handlePreviousPage = () => dispatch(previousPage());
    const handleFilter = (f) => dispatch(setFilter(f));

    const handleSearchConfirm = (value) => dispatch(setSearch(value));
    const handleDeleteTask = () => {

    }



    const TableContent = () => {
        return(
            <>
            
            </>
        )
    }


    React.useEffect(() => {
        if(toastType) toast({
            title:toastValue,
            status: toastType,
            duration: 4000,
            isClosable: true,
        })
        dispatch(setToastType(null))
    })

    React.useEffect(() => {
        dispatch(getData(page,PAGE_SIZE,search,filter))
    }, [page,search,filter])


    return(
        <FadeIn>
            <Datagrid 
                tableHeader={TABLE_HEAD}
                tableContent={<TableContent />}

                loading={loading}
                error={error}

                results={data}
                page={page}
                maxPage={maxPage}

                nextPage={() => handleNextPage()}
                prevPage={() => handlePreviousPage()}
                confirmSearch={(s) => handleSearchConfirm(s)}
                setFilter={((f) => handleFilter(f))}
            />
        </FadeIn>
        
    )

}

export default StaffSalesDock;



